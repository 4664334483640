import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import blogReducer from './blogReducer'
import adminReducer from './adminReducer'
import productReducer from './productReducer'
import cartReducer from './cartReducer'
import galleryReducer from './galleryReducer'
import ccbillReducer from './ccbillReducer'
import chatReducer from './chatReducer'
import utilityReducer from './utilityReducer'
import shippingAddressReducer from './shippingAddressReducer'
import copyToClipboardReducer from './copyToClipboardReducer'
import chatBookingTimerReducer from './chatBookingTimerReducer'
import socketReducer from './socketReducer'
import liveStreamReducer from './liveStreamReducer'
import promotionReducer from './promotionReducer'
import offerReducer from './offerReducer'
import sweetAlertReducer from './sweetAlertReducer'
import influencerHelpReducer from './influencerHelpReducer'
import pushNotification from './pushNotification'
import userInfluencerHelpReducer from './userInfluencerHelpReducer'
import universalLogin from './universalLoginReducer'
import loginReducer from './loginReducer'
import registerReducer from './registerReducer'
import paymentReducer from './paymentReducer'
import settingsReducer from './settingsReducer'
import pageReducer from './pageReducer'
import couponCodeReducer from './couponReducer'

export default combineReducers({
    auth: authReducer,
    blog: blogReducer,
    product: productReducer,
    errors: errorReducer,
    admin: adminReducer,
    cart: cartReducer,
    gallery: galleryReducer,
    ccbill: ccbillReducer,
    chat: chatReducer,
    utility: utilityReducer,
    shipping: shippingAddressReducer,
    copyToClipboard: copyToClipboardReducer,
    chatBookingTimer: chatBookingTimerReducer,
    socket: socketReducer,
    liveStream: liveStreamReducer,
    promotion: promotionReducer,
    resubscriptionOffer: offerReducer,
    sweetAlert: sweetAlertReducer,
    influencerHelp: influencerHelpReducer,
    pushNotification: pushNotification,
    userInfluencerHelp: userInfluencerHelpReducer,
    universalLogin: universalLogin,
    login: loginReducer,
    register: registerReducer,
    payment: paymentReducer,
    settings: settingsReducer,
    page: pageReducer,
    couponCode: couponCodeReducer
})
