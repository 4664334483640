import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LockIcon from '@material-ui/icons/Lock'
import PublicIcon from '@material-ui/icons/Public'
import {
    Select,
    MenuItem,
    Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import WebsiteFontColor from './WebsiteFontColor'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { FILE_SIZE_LIMIT_IN_BYTE } from '../../utils/constant'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import { isIOS } from 'react-device-detect'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { getFileExtension } from '../../utils/common'
import _ from 'lodash'
import { editPost } from '../../actions/blogActions'
import axios from 'axios'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import { setGoogleAnalyticsEvent } from '../../utils/GoogleAnalyticsEvent'
import HelperModelForPreview from '../gallery/HelperModelForPreview'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const useStyles = () => ({
    selectForm: {
        padding: '5px',
        height: '40px',
        border: 'none'
    }
})

const DropZoneSection = styled.section`
    width: 100%;
    height: fit-content;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 12px;
    border-color: ${props => props.site_font_color};
`

const CloseButton = styled.button`
    right: 5px; 
    top: 0;
    position: absolute; 
    border-radius: 82%; 
    width: 24px;
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    cursor: pointer;

    :before {
        font-size: medium;
    }

    @media(max-width: 767px) {
        right: ${props => props.isRearrangeModeEnable === true ? '20%' : '13%'};
    }
`

const PlusButton = styled.label`
    background-color: ${props => props.content_color};
    color: ${props => props.content_font_color};
    height: 100%;
    border-radius: 5px;
    cursor: ${props => props.disabled === false ? 'pointer' : 'inherit'};
    display: flex;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
`

const ColumnDiv = styled.div`
    width: 100%;
    margin-bottom: ${props => props.isUploading === true ? '50px' : '20px'};
    
    .positioning {
        display: none !important;
    }

    .form-group {
        position: absolute;
        bottom: -24px;
    }

    .progress-span {
        position: absolute;
        bottom: -44px;
        width: 88%;
    }

    .progress {
        border-radius: 5px;
    }

    .progress-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media(max-width: 767px) {
        .positioning {
            display: inline-block !important;
            width: 20%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }

        .positioning i {
            color: ${props => props.site_font_color};
            border-radius: 50%;
            width: 29px;
            height: 29px;
        }
    }
`

const VideoDiv = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    video{
        max-width: 210px;
        object-fit: cover;
        border-radius: 5px;
    }
    
    .progress {
        border-radius: 5px;
    }
    
    @media (min-width:991px) and (max-width:1280px) {

        video{
            max-width: 153px;
        }
    }
    @media (min-width:767px) and (max-width:991px) {

        video{
            max-width: 183px;
        }
    }
    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block;
        video{
            max-width: 100%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(45.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const DivData = styled.div`
    background-image: ${props => props.imageUrl};
    height:150px;
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
    background-color: #000000;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: inherit;

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block !important;
        margin-bottom: 1%;
    }

`

const MarkAsPreviewButton = styled.div`
    color:${props => props.site_font_color} !important;
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: ${props => props.site_font_color} !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: ${props => props.site_font_color} !important;
    }
`

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

function EditBlog(props) {
    const appSettings = props.auth.appSettings
    const [mediaPreview, setMediaPreview] = useState(props.mediaPreview)
    const [description, setDescription] = useState(props.description)
    const [captionBlur, setCaptionBlur] = useState(props.captionBlur)
    const [editPostOption, setEditPostOption] = useState(props.editPostOption)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [uploadProgress, setUploadProgress] = useState({ type: '' })
    const [disableButtons, setDisableButtons] = useState(false)
    const [isMediaUpdate, setIsMediaUpdate] = useState(false)
    const [isPreviewModeEnable, setIsPreviewModeEnable] = useState(props.mediaPreview.length > 0 ? true : false)
    const [selectedPreviewIndex, setSelectedPreviewIndex] = useState('')
    const [helperModelOpen, setHelperModelOpen] = useState(false)

    useEffect(() => {
        if (['true', true].includes(props.captionBlur)) {
            setCaptionBlur('enabledCaptionBlur')
        } else if (['false', false].includes(props.captionBlur)) {
            setCaptionBlur('disabledCaptionBlur')
        }
    }, [props.captionBlur])

    const galleryFilesFromPreview = (files) => {
        if (props.mediaPreview.length === 0) {
            galleryFiles([files[0]], 'preview')
        } else {
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
    }

    const galleryFiles = (files, name) => {
        const existFilesCount = mediaPreview.length
        const uploadFileCount = 1 - existFilesCount < files.length ? 1 - existFilesCount : files.length
        const totalFilesCount = files.length + existFilesCount
        for (let index = 0; index < uploadFileCount; index++) {
            const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
            if (!acceptExtension.includes(files[index].type)) {
                return props.setSweetAlert({ description: 'Media format is not supported.' })
            }
        }

        for (let index = 0; index < uploadFileCount; index++) {
            const fileSize = _.get(files[index], 'size', -1)
            if (fileSize !== -1 && fileSize > FILE_SIZE_LIMIT_IN_BYTE) {
                props.setSweetAlert({ description: `File ${files[index].name} size is too large.` })
            } else {
                handleAddFile(files[index], name)
            }
        }
        if (totalFilesCount > 1) {
            props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 1 images allowed.` })
        }
    }

    const handleAddFile = (file) => {
        if (file) {
            let newFile = {
                selectedFile: file,
                renderFile: URL.createObjectURL(file),
                is_process: true
            }
            setIsMediaUpdate(true)
            setMediaPreview([newFile])
        }
    }

    const openDialog = (url, type) => {
        if (disableButtons === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    const getGalleryProgress = (index) => {
        const progress = _.get(uploadProgress, 'progress', '')
        const progressIndex = _.get(uploadProgress, 'index', '')
        const progressNumber = _.get(uploadProgress, 'progressNumber', '')
        if (progress !== '' && progressIndex === index) {
            return <span className='progress-span mt-1'>
                <div className="progress">
                    <div
                        className='progress-bar progress-bar-striped progress-bar-animated'
                        role='progressbar'
                        style={{ width: progress, backgroundColor: appSettings.content_color, color: appSettings.content_font_color }}
                    >{progress}</div>
                </div>
                {uploadProgressInMb(progressIndex, progressNumber)}
            </span>
        }
        return <></>
    }

    const uploadProgressInMb = (galleryIndex, progressNumber) => {
        let actualFileSize = 0
        let actualFileUploaded = '0'
        const selectedFile = mediaPreview[galleryIndex].selectedFile
        actualFileSize = (_.get(selectedFile, 'size', 0) / 1024) / 1024
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text' style={{ color: props.auth.appSettings.site_font_color }}>Uploading {actualFileUploaded} MB Of {actualFileSize.toFixed(2)} MB</div>
    }

    const previewContent = (name) => {
        const fileArray = name === 'original' ? props.media : mediaPreview
        return <div className='row justify-content-center'>
            {
                fileArray.map((item, i) => {
                    let url = ''
                    let isUpdatePreview = false
                    if (item.url === undefined) {
                        url = 'url(' + item.renderFile + ')'
                    } else {
                        url = 'url(' + item.url + ')'
                        isUpdatePreview = true
                    }
                    return (
                        <ColumnDiv
                            isUploading={disableButtons}
                            content_color={appSettings.content_color}
                            site_font_color={appSettings.site_font_color}
                            key={i}
                            className='align-items-center col-12 col-md-4 d-flex justify-content-center flex-column mt-3'>
                            {['video/quicktime', 'video/mp4', 'video'].includes(isUpdatePreview === true ? item.content_type : item.selectedFile.type) ?
                                <>
                                    {name === 'preview' &&
                                        <CloseButton
                                            className='align-items-center close d-flex justify-content-center mr-4 mt-2'
                                            content_color={appSettings.content_color}
                                            content_font_color={appSettings.content_font_color}
                                            type={'video'}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setIsMediaUpdate(true)
                                                setMediaPreview([])
                                                setSelectedPreviewIndex('')
                                            }}
                                            disabled={disableButtons}
                                            style={{ zIndex: '5' }}
                                        />
                                    }
                                    <VideoDiv
                                        onClick={() => { openDialog(isUpdatePreview === true ? item.url : item.renderFile, 'video') }}
                                        draggable={false}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        {/* <OverLayDiv type='video' isRearrangeModeEnable={isRearrangeModeEnable} /> */}
                                        {isIOS ?
                                            <video src={isUpdatePreview === true ? item.url : item.renderFile} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                            :
                                            <video src={isUpdatePreview === true ? item.url : item.renderFile} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                        }
                                        <GalleryIcon>
                                            <span className='video-button-span'>
                                                <i className='fas fa-play-circle play-icon' />
                                            </span>
                                        </GalleryIcon>
                                    </VideoDiv>
                                    {name === 'preview' && getGalleryProgress(i)}
                                    {disableButtons && getGalleryProgress(i)}
                                    {name === 'original' && isPreviewModeEnable &&
                                        <div className='form-check mt-2'>
                                            <input className='form-check-input' type='checkbox' checked={i === selectedPreviewIndex} name='media_preview' onChange={() => onMarkAsPreview(item, i)} id={`media_preview${i}`} disabled={disableButtons} />
                                            <label className='form-check-label' style={{ color: appSettings.site_font_color }} htmlFor={`media_preview${i}`}>
                                                Set as Preview
                                            </label>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    {name === 'preview' &&
                                        <CloseButton
                                            className='align-items-center close d-flex justify-content-center mr-3 mt-2'
                                            content_color={appSettings.content_color}
                                            content_font_color={appSettings.content_font_color}
                                            type={'image'}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setIsMediaUpdate(true)
                                                setMediaPreview([])
                                                setSelectedPreviewIndex('')
                                            }}
                                            disabled={disableButtons}
                                            style={{ zIndex: '5' }}
                                        // isRearrangeModeEnable={isRearrangeModeEnable}
                                        />
                                    }
                                    <DivData
                                        imageUrl={url}
                                        onClick={() => { openDialog(isUpdatePreview === true ? item.url : item.renderFile, 'photo') }}
                                        draggable={false}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        {/* <OverLayDiv type='image' isRearrangeModeEnable={isRearrangeModeEnable} /> */}
                                    </DivData>
                                    {name === 'preview' && getGalleryProgress(i)}
                                    {disableButtons && getGalleryProgress(i)}
                                    {name === 'original' && isPreviewModeEnable &&
                                        <div className='form-check mt-2'>
                                            <input className='form-check-input' type='checkbox' checked={i === selectedPreviewIndex} name='media_preview' onChange={() => onMarkAsPreview(item, i)} id={`media_preview${i}`} disabled={disableButtons} />
                                            <label className='form-check-label' style={{ color: appSettings.site_font_color }} htmlFor={`media_preview${i}`}>
                                                Set as Preview
                                            </label>
                                        </div>
                                    }
                                </>
                            }
                        </ColumnDiv>
                    )
                })
            }
        </div >
    }

    const handleGalleryChange = (e, name) => {
        const files = e.target.files
        if (name === 'preview') {
            if (files.length > 1) {
                return props.setSweetAlert({ description: 'You can not add more than one preview.' })
            } else {
                handleAddFile(files[0], name)
            }
        } else {
            galleryFiles(files, name)
        }
    }

    const getPlusButton = (name) => {
        let id = `button-gallery-${name}`
        return <>
            <div className='col-12 col-md-4 mt-3 mb-2'
                style={{
                    height: '150px',
                    marginBottom: '3%',
                    margin: (name !== 'original' && mediaPreview.length === 0) ? 'auto' : '0%',
                    maxWidth: '150px',
                    outlineColor: 'transparent'
                }}
                name={name}
            >
                <PlusButton
                    htmlFor={id}
                    content_color={appSettings.content_color}
                    fullWidth={true}
                    className='align-items-center justify-content-center'
                    content_font_color={appSettings.content_font_color}
                    // disabled={props.disableButtons}
                    name={name}
                    button_shadow_color={appSettings.button_shadow_color}
                >
                    <input
                        accept='image/png,image/jpg,image/jpeg,video/mp4,video/quicktime'
                        // className={classes.upload_input}
                        id={id}
                        onChange={(e) => handleGalleryChange(e, name)}
                        style={{ display: 'none' }}
                        type='file'
                        // disabled={props.disableButtons}
                        multiple={true}
                        name={name}
                    />
                    <i className="fas fa-plus" aria-hidden="true"></i>
                </PlusButton>
            </div>
        </>
    }

    const uploadFileUsingPresignedUrl = async (contentType, url, body, galleryIndex = 0, type = 'original') => {
        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    setDisableButtons(false)
                    return props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })

                const object = {
                    progress: percentCompleted + '%',
                    index: galleryIndex,
                    progressNumber: percentCompleted,
                    type: type
                }
                setUploadProgress(object)
            },
            cancelToken: cancelTokenSource.token
        }
        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    const handlePostGallerySubmit = async (e) => {
        e.preventDefault()
        if (description.trim() === '') {
            return props.setSweetAlert({ description: 'Please Add Caption' })
        }
        if (mediaPreview.length > 1) {
            return props.setSweetAlert({ description: 'You can only select 1 content' })
        }

        const obj = {
            postId: props.postId,
            description: description,
            pageNum: props.blog.currentPage,
            isCaptionBlur: captionBlur === 'enabledCaptionBlur' ? true : false,
            isPublicPost: editPostOption === 'Public' ? true : false,
            isMediaUpdate: isMediaUpdate,
            media_preview: [],
            processing: false
        }
        setDisableButtons(true)
        // preview image upload
        for (let index = 0; index < mediaPreview.length; index++) {
            if (mediaPreview[index].is_process === true) {
                const element = mediaPreview[index]

                let fileExtension = getFileExtension(element.selectedFile.name)
                let fileExtensionForPresignedUrl = 'photo'
                if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                    fileExtensionForPresignedUrl = 'video'
                }

                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                    if (uploadFile === true) {
                        obj.media_preview.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl
                        })
                        obj.contentLeftForProcessing = 1
                        obj.processing = true
                    } else {
                        setDisableButtons(false)
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    setDisableButtons(false)
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return props.setSweetAlert({ description: presignedUrlData.message })
                }
            } else {
                obj.media_preview = mediaPreview
                obj.selectedPreviewIndex = selectedPreviewIndex
            }
        }
        props.editPost(obj, () => {
            setDisableButtons(false)
            props.handleEditBlogDialogClose()
            return props.setSweetAlert({ description: 'Post updated successfully.' })
        })
    }

    const onChangePreviewMode = () => {
        if (isPreviewModeEnable === true) {
            setMediaPreview([])
        }
        setSelectedPreviewIndex('')
        setIsPreviewModeEnable(!isPreviewModeEnable)
    }

    const onMarkAsPreview = (previewMedia, index) => {
        previewMedia.is_selected_from_original_file = true
        setMediaPreview([previewMedia])
        setSelectedPreviewIndex(index)
        setIsMediaUpdate(true)
    }

    return (<>
        <WebsiteFontColor>
            {isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openDialog('', '') }}
                    type={type}
                />
            }
            {isDialogOpen === false &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: appSettings.card_background_color }}>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Edit Post</h5>
                                <button className='close' onClick={props.handleEditBlogDialogClose} style={{ color: appSettings.site_font_color, opacity: 1 }} disabled={disableButtons} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <textarea
                                                id='description'
                                                name='description'
                                                className='form-control'
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                disabled={disableButtons}
                                            />
                                        </div>
                                    </div>
                                    {props.isEditPostLocked &&
                                        <>
                                            <div className='mt-2 mb-2'>
                                                <DropZoneSection content_color={appSettings.content_color} site_font_color={appSettings.site_font_color}>
                                                    <div style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                                                        <h4 style={{ color: appSettings.site_font_color, marginBottom: '0%' }}>Media</h4>
                                                        {previewContent('original')}
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 px-5' style={{ textAlign: 'left' }}>
                                                            <MarkAsPreviewButton content_color={appSettings.content_color} site_font_color={appSettings.site_font_color}>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="custom-control-input"
                                                                        id="PREVIEW_MODE_REUSE"
                                                                        name='PreviewMode'
                                                                        onChange={onChangePreviewMode}
                                                                        checked={isPreviewModeEnable}
                                                                        disabled={disableButtons}
                                                                    />
                                                                    <label className="custom-control-label" htmlFor="PREVIEW_MODE_REUSE">Add Preview</label>
                                                                </div>
                                                                <HelperButton className='mx-1'
                                                                    disabled={disableButtons}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setHelperModelOpen(true)
                                                                    }} style={{ backgroundColor: 'transparent', color: appSettings.content_color }}>
                                                                    <i className='fas fa-question-circle'></i>
                                                                </HelperButton>
                                                            </MarkAsPreviewButton>
                                                        </div>
                                                    </div>
                                                </DropZoneSection>
                                            </div>
                                            {isPreviewModeEnable &&
                                                <div className='mt-2 mb-2'>
                                                    <Dropzone multiple={true} onDrop={galleryFilesFromPreview}>
                                                        {({ getRootProps }) => (
                                                            <DropZoneSection content_color={appSettings.content_color} site_font_color={appSettings.site_font_color}>
                                                                <div {...getRootProps()} style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                                                                    <h4 style={{ color: appSettings.site_font_color, marginBottom: '0%' }}>Preview</h4>
                                                                    {previewContent('preview')}
                                                                    {mediaPreview.length === 0 &&
                                                                        getPlusButton('preview')
                                                                    }
                                                                </div>
                                                            </DropZoneSection>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            }
                                        </>
                                    }
                                    <div className='form-row'>
                                        <div className='col'>
                                            <label>Blur Caption</label>
                                            <div className='form-group'>
                                                <div className='from-check form-check-inline'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        value='enabledCaptionBlur'
                                                        checked={captionBlur === 'enabledCaptionBlur'}
                                                        onChange={() => setCaptionBlur('enabledCaptionBlur')}
                                                        name='caption_blur'
                                                        id='CAPTION_BLUR_ENABLED'
                                                    />
                                                    <label className='form-check-label' htmlFor='CAPTION_BLUR_ENABLED'>Enable Blur</label>
                                                </div>
                                                <div className='from-check form-check-inline'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        value='disabledCaptionBlur'
                                                        checked={captionBlur === 'disabledCaptionBlur'}
                                                        onChange={() => setCaptionBlur('disabledCaptionBlur')}
                                                        name='caption_blur'
                                                        id='CAPTION_BLUR_DISABLED'
                                                    />
                                                    <label className='form-check-label' htmlFor='CAPTION_BLUR_DISABLED'>Disable Blur</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!props.isEditPostLocked &&
                                        <div className='row mb-3'>
                                            <div className='col'>
                                                <Select name='editPostOption' value={editPostOption} onChange={(e) => setEditPostOption(e.target.value)} className={props.classes.selectForm} style={{ color: appSettings.site_font_color }}>
                                                    <MenuItem value='Private'>
                                                        <LockIcon style={{ fontSize: '25px', verticalAlign: 'middle' }} />
                                                        <Typography component='span' style={{ paddingLeft: '10px' }}>Private</Typography>
                                                    </MenuItem>
                                                    <MenuItem value='Public'>
                                                        <PublicIcon style={{ fontSize: '25px', verticalAlign: 'middle' }} />
                                                        <Typography component='span' style={{ paddingLeft: '10px' }}>Public</Typography>
                                                    </MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    }
                                    <div className='row'>
                                        <div className='col'>
                                            <button
                                                className='btn btn-block'
                                                style={{
                                                    backgroundColor: appSettings.content_color,
                                                    color: appSettings.content_font_color,
                                                    boxShadow: `0 5px 15px 0 ${appSettings.button_shadow_color}44, 0 4px 15px 0 ${appSettings.button_shadow_color}44`
                                                }}
                                                onClick={handlePostGallerySubmit}
                                                disabled={disableButtons}
                                            >
                                                {disableButtons === true && <span className="spinner-border spinner-border-sm mr-2"></span>} Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {helperModelOpen === true &&
                <HelperModelForPreview setHelperModelOpen={setHelperModelOpen} />
            }
        </WebsiteFontColor>
    </>
    )
}

EditBlog.propTypes = {
    auth: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleEditBlogDialogClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    editPostOption: PropTypes.string.isRequired,
    captionBlur: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    isEditPostLocked: PropTypes.bool.isRequired,
    mediaPreview: PropTypes.array,
    setSweetAlert: PropTypes.func.isRequired,
    postId: PropTypes.string.isRequired,
    blog: PropTypes.object.isRequired,
    editPost: PropTypes.func.isRequired,
    media: PropTypes.array
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog
})

export default connect(
    mapStateToProps, { setSweetAlert, editPost }
)(withRouter(withStyles(useStyles)(EditBlog)))
