import React, { useRef, useEffect } from 'react'
import Carousel from 'react-gallery-carousel'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import 'react-gallery-carousel/dist/index.css'
import OverlayContainer from './OverlayStyle'
import { isIOS } from 'react-device-detect'
import { v4 as uuidv4 } from 'uuid'

const CarouselDiv = styled.div`
    width: 100%;
    height: 100%;

    .pcp-gallery-slider {
        background: transparent;
    }

    .pcp-gallery-slider li {
        padding-right: 50px;
        padding-left: 50px;
        max-width: 100%;
    }

    .pcp-gallery-slider li > div {
        height: 100vh !important
    }

    .pcp-gallery-slider div > span {
        background: transparent !important;
        color: white;
    }

    video {
        width: 100%;
    }

    img {
        object-fit: contain;
    }

    video, img {
        max-height: var(--app-height);
    }

    @media(max-width: 767px) {
        .pcp-gallery-slider li {
            padding-right: 0;
            padding-left: 0;
        }

        video, img {
            max-height: ${props => props.isIOS ? 'var(--app-height)' : '90vh'};
        }

        .pcp-gallery-slider li > div {
            height: ${props => props.isIOS ? 'var(--app-height)' : '90vh'} !important;
        }

        .sc-frDJqD {
            padding: 40px 12px 40px 40px !important;
        }
    
        .sc-hmzhuo {
            padding: 40px 40px 40px 12px !important;
        }
    }

    .sc-frDJqD {
        padding: 70px 12px 70px 70px;
    }

    .sc-hmzhuo {
        padding: 70px 70px 70px 12px;
    }
`

const Image = styled.img`
    margin: auto;
    display: block;
    object-fit: contain;
    pointer-events: none;
`

const PreviousMedia = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    cursor: pointer;
    z-index: 1;
    color: ${props => props.color};
    padding: 12px;
`

const NextMedia = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    cursor: pointer;
    z-index: 1;
    color: ${props => props.color};
    padding: 12px;
`

const GalleryCarousel = (props) => {
    const userId = props.auth.user._id
    const media = _.get(props, 'media', [])
    const currentIndex = _.get(props, 'currentIndex', 0)
    const content_font_color = _.get(props, 'auth.appSettings.content_font_color', '')
    const itemsRef = useRef([])

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, media.length)
        appHeight()
        window.addEventListener('resize', appHeight)
    }, [media])

    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    const onIndexChange = () => {
        _.forEach(itemsRef.current, (item) => {
            if (item.pause) { item.pause() }
        })
    }

    const options = {
        index: currentIndex,
        hasThumbnails: false,
        minIcon: false,
        maxIcon: false,
        playIcon: false,
        pauseIcon: false,
        hasIndexBoard: 'bottomLeft',
        canAutoPlay: false,
        className: 'pcp-gallery-slider',
        leftIcon: <PreviousMedia color={content_font_color}><i className='fas fa-chevron-left fa-2x'></i></PreviousMedia>,
        rightIcon: <NextMedia color={content_font_color}><i className='fas fa-chevron-right fa-2x'></i></NextMedia>,
        onIndexChange: onIndexChange
    }

    return <CarouselDiv isIOS={isIOS} key={uuidv4()}>
        <Carousel {...options}>
            {media.map((item, i) => {
                const { thumbnail_url, url, content_type } = item
                const isVideo = content_type === 'video' ? true : false
                return <OverlayContainer userId={userId} key={i} className='gallery-content-overlay'>
                    {isVideo ?
                        <video
                            key={i}
                            controls
                            controlsList='nodownload'
                            poster={thumbnail_url}
                            ref={element => itemsRef.current[i] = element}
                        ><source src={url}></source></video>
                        : <Image key={i} draggable={false} src={url} alt='' ref={element => itemsRef.current[i] = element} />
                    }
                </OverlayContainer>
            })}
        </Carousel>
    </CarouselDiv>
}

GalleryCarousel.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.array,
    previousImage: PropTypes.func,
    nextImage: PropTypes.func,
    currentMediaIndex: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(GalleryCarousel))
