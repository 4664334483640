import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import WebsiteFontColor from './../Layouts/WebsiteFontColor'
import { getBlogDataById, postReviewedBlog } from './../../actions/blogActions'
import styled from 'styled-components'
import _ from 'lodash'
import { setSweetAlert } from './../../actions/sweetAlertActions'
import { getFileExtension } from './../../utils/common'
import axios from 'axios'
import { isIOS } from 'react-device-detect'
import FullScreenModelPopUpDialog from './../Layouts/FullScreenModelPopUpDialog'
import ReactTooltip from 'react-tooltip'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import { setGoogleAnalyticsEvent } from '../../utils/GoogleAnalyticsEvent'
import CreatableSelect from 'react-select/creatable'
import { getCloudFrontAssetsUrl } from '../../utils/assets'

const PlusButton = styled.label`
    background-color: ${props => props.content_color};
    color: ${props => props.content_font_color};
    height: 100%;
    border-radius: 5px;
    cursor: ${props => props.disabled === false ? 'pointer' : 'inherit'};
    display: flex;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
`

const ColumnDiv = styled.div`
    width: 100%;
    margin-bottom: ${props => props.isUploading === true ? '50px' : '20px'};
    
    .positioning {
        display: none !important;
    }

    .form-group {
        position: absolute;
        bottom: -24px;
    }

    .progress-span {
        position: absolute;
        bottom: -44px;
        width: 88%;
    }
    .progress {
        border-radius: 5px;
    }

    .progress-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media(max-width: 767px) {
        .positioning {
            display: inline-block !important;
            width: 20%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }

        .positioning i {
            color: ${props => props.site_font_color};
            border-radius: 50%;
            width: 29px;
            height: 29px;
        }
    }
`

const CloseButton = styled.button`
    right: 5px; 
    top: 0;
    position: absolute; 
    border-radius: 82%; 
    width: 24px;
    background-color: ${props => props.content_color} !important; 
    color: ${props => props.content_font_color} !important;
    cursor: pointer;

    :before {
        font-size: medium;
    }

    @media(max-width: 767px) {
        right: ${props => props.isRearrangeModeEnable === true ? '20%' : '13%'};
    }
`

const VideoDiv = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    video{
        object-fit: cover;
        border-radius: 5px;
    }
    
    .progress {
        border-radius: 5px;
    }

    @media (min-width:991px) and (max-width:1280px) {

        video{
            max-width: 153px;
        }
    }
    @media (min-width:767px) and (max-width:991px) {

        video{
            max-width: 183px;
        }
    }
    
    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block;
        video{
            max-width: 100%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }
    }
`

const OverLayDiv = styled.div`
    width: 100%;
    height: ${props => props.type === 'video' ? '150px' : '100%'};
    max-width: ${props => props.type === 'video' ? '210px' : '100%'};
    position: absolute;
    background-color: #FFFFFF4F;
    z-index: 4;
    padding: 0 20px;
    border-radius: 5px;
    display: ${props => props.isRearrangeModeEnable === true ? 'block' : 'none'};
    left: ${props => props.type === 'video' ? '3px' : '0px'};

    @media (min-width:991px) and (max-width:1280px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '153px' : '100%'};
    }
    @media (min-width:767px) and (max-width:991px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '183px' : '100%'};        
    }
    @media (max-width:767px) {
        left: 0px !important;
        max-width: 100% !important;
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(45.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const DivData = styled.div`
    background-image: ${props => props.imageUrl};
    height:150px;
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
    background-color: #000000;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: inherit;

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block !important;
        margin-bottom: 1%;
    }
`

const ReorderButton = styled.div`
    color:${props => props.site_font_color} !important;
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: ${props => props.site_font_color} !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: ${props => props.site_font_color} !important;
    }
`

const DropZoneSection = styled.section`
    width: 100%;
    height: fit-content;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 12px;
    border-color: ${props => props.site_font_color};
`

function ReviewBlog(props) {
    const {
        card_background_color,
        content_color,
        content_font_color,
        site_font_color,
        content_unlock_minimum_amount,
        content_unlock_maximum_amount,
        button_shadow_color,
        feed_tags_enable,
        feed_tags
    } = props.auth.appSettings
    const [blog, setBlog] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [previewFile, setPreviewFile] = useState([])
    const [uploadProgress, setUploadProgress] = useState({
        progress: '',
        index: '',
        progressNumber: '',
        type: ''
    })
    const [newFiles, setNewFiles] = useState([])
    const [isRearrangeModeEnable, setIsRearrangeModeEnable] = useState(false)
    const [dragId, setDragId] = useState('')
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [description, setDescription] = useState('')
    const [privateBlur, setPrivateBlur] = useState(true)
    const [captionBlur, setCaptionBlur] = useState(true)
    const [amount, setAmount] = useState(5)
    const [feedTags, setFeedTag] = useState({})
    const [feedTagErrorText, setFeedTagErrorText] = useState('')

    const validFiles = useMemo(() => {
        if (blog !== false) {
            const files = blog.media.filter((data) => data.is_error !== true)
            setNewFiles(files)
        }
        return []
    }, [blog])

    useEffect(() => {
        props.getBlogDataById(props.blog_id, (res) => {
            if (res) {
                setBlog(res.data)
                setDescription(res.data.description)
                setPrivateBlur(res.data.privateBlur)
                setCaptionBlur(res.data.captionBlur)
                setAmount(res.data.amount)
                setIsLoadingData(false)
                if (res.data.feed_tags !== undefined) {
                    const tags = res.data.feed_tags.map(item => {
                        let tag = props.blog.tagList.find(option => option.value === item.id)
                        return { label: tag.label, value: item.id }
                    })
                    setFeedTag(tags)
                }
            } else {
                alert('Something went wrong')
                props.onClose()
            }
        })
    }, [props.blog_id])

    const errorFiles = useMemo(() => {
        if (blog !== false) {
            return blog.media.filter((data) => data.is_error === true)
        }
        return []
    }, [blog])

    const errorPreviewFile = useMemo(() => {
        if (blog !== false) {
            return blog.media_preview.filter((data) => data.is_error === true)
        }
        return []
    }, [blog])

    const errorText = useMemo(() => {
        if (blog !== false) {
            if (errorPreviewFile.length > 0 && errorFiles.length === 0) {
                return 'There was an error uploading the following preview media. Please try to post again, or add new preview media below.'
            } else if (errorPreviewFile.length > 0 && errorFiles.length > 0) {
                return 'There was an error uploading the following media. Please try to post again, or add new media above. The preview media was not uploaded, so be sure to choose the Add Preview option to try again.'
            } else {
                return 'There was an error uploading the following media. Please try to post again, or add new media above.'
            }
        }
        return ''
    }, [blog])

    const handleGalleryChange = (e, name) => {
        const files = e.target.files
        if (name === 'original') {
            galleryFiles(files)
        } else {
            previewFiles(files)
        }
    }

    const previewFiles = (files) => {
        if (files.length > 1) {
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
        const file = files[0]
        const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
        if (!acceptExtension.includes(file.type)) {
            return props.setSweetAlert({ description: 'Media format is not supported.' })
        }

        if (file) {
            let newFile = {
                selectedFile: file,
                renderFile: URL.createObjectURL(file),
                is_new_file: true
            }
            setPreviewFile([newFile])
        }
    }

    const galleryFiles = (files) => {
        const existFilesCount = validFiles.length
        const uploadFileCount = 45 - existFilesCount < files.length ? 45 - existFilesCount : files.length
        const totalFilesCount = files.length + existFilesCount
        for (let index = 0; index < uploadFileCount; index++) {
            const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
            if (!acceptExtension.includes(files[index].type)) {
                return props.setSweetAlert({ description: 'Media format is not supported.' })
            }
        }

        let newUploadedFiles = []
        for (let index = 0; index < uploadFileCount; index++) {
            if (files[index]) {
                const newFileName = files[index].name
                const newFileSize = files[index].size

                let findRecord = _.findIndex(newFiles, function (n) {
                    return (n.is_new_file === true && n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                })
                if (findRecord === -1) {
                    let newFile = {
                        selectedFile: files[index],
                        renderFile: URL.createObjectURL(files[index]),
                        is_new_file: true
                    }
                    newUploadedFiles.push(newFile)
                }
            }
        }

        if (newUploadedFiles.length > 0) {
            let copyNewFiles = [...newFiles]
            copyNewFiles = copyNewFiles.concat(newUploadedFiles)
            setNewFiles(copyNewFiles)
        }

        if (totalFilesCount > 45) {
            props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 45 images allowed.` })
        }
    }

    const uploadFileUsingPresignedUrl = async (contentType, url, body, galleryIndex = 0, type = 'original') => {
        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    setIsLoading(false)
                    return props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })

                const object = {
                    progress: percentCompleted + '%',
                    index: galleryIndex,
                    progressNumber: percentCompleted,
                    type
                }
                setUploadProgress(object)
            },
            cancelToken: cancelTokenSource.token
        }
        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    const handlePostGallerySubmit = async (e) => {
        e.preventDefault()

        if (newFiles.length === 0) {
            return props.setSweetAlert({ description: 'Please Select Media' })
        }

        if ((amount < content_unlock_minimum_amount) || (amount > content_unlock_maximum_amount)) {
            props.setSweetAlert({ description: `Choose an amount between ${content_unlock_minimum_amount} and ${content_unlock_maximum_amount}` })
            return setAmount(content_unlock_minimum_amount)
        }
        setIsLoading(true)

        for (let index = 0; index < newFiles.length; index++) {
            const element = newFiles[index]
            if (element.is_new_file === true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    setIsLoading(false)
                    return props.setSweetAlert({ description: `Image Number ${index + 1} media format is not supported.` })
                }
            }
        }

        for (let index = 0; index < previewFile.length; index++) {
            const element = previewFile[index]
            let fileExtension = getFileExtension(element.selectedFile.name)
            fileExtension = fileExtension.toUpperCase()
            let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
            if (!acceptedFileExtensions.includes(fileExtension)) {
                setIsLoading(false)
                return props.setSweetAlert({ description: 'Preview media format is not supported.' })
            }
        }

        let galleryArray = [...newFiles]
        const newFilesArray = galleryArray.filter((file) => file.is_new_file === true)
        const obj = {
            blog_id: props.blog_id,
            contentLeftForProcessing: newFilesArray.length + previewFile.length,
            privateBlur,
            captionBlur,
            description,
            media: [],
            media_preview: [],
            feed_tags: feedTags
        }

        if (blog.isLocked) {
            obj.amount = amount
        }
        for (let index = 0; index < previewFile.length; index++) {
            const element = previewFile[index]

            let fileExtension = getFileExtension(element.selectedFile.name)
            let fileExtensionForPresignedUrl = 'photo'
            if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                fileExtensionForPresignedUrl = 'video'
            }

            let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
            if (presignedUrlData.presigned_url !== '') {
                let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                if (uploadFile === true) {
                    obj.media_preview.push({
                        url: presignedUrlData.file_name,
                        is_process: true,
                        content_type: fileExtensionForPresignedUrl,
                        is_error: false
                    })
                } else {
                    setIsLoading(false)
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return props.setSweetAlert({ description: presignedUrlData.message })
                }
            } else {
                setIsLoading(false)
                setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                return props.setSweetAlert({ description: presignedUrlData.message })
            }
        }

        for (let index = 0; index < galleryArray.length; index++) {
            const element = galleryArray[index]
            if (element.is_new_file === true) {
                let fileExtension = getFileExtension(element.selectedFile.name)
                let fileExtensionForPresignedUrl = 'photo'
                if (['mp4', 'mov', 'MP4', 'MOV'].includes(fileExtension)) {
                    fileExtensionForPresignedUrl = 'video'
                }

                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'blog', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index)
                    if (uploadFile === true) {
                        obj.media.push({
                            url: presignedUrlData.file_name,
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl,
                            is_error: false,
                            is_new_file: true
                        })
                    } else {
                        setIsLoading(false)
                        setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    setIsLoading(false)
                    setGoogleAnalyticsEvent('presigned_url_generate', 'error', presignedUrlData.message)
                    return props.setSweetAlert({ description: presignedUrlData.message })
                }
            } else {
                obj.media.push({
                    url: element.url,
                    is_process: false,
                    content_type: element.content_type,
                    is_error: false
                })
            }
        }
        props.postReviewedBlog(obj, () => {
            props.onClose()
            setIsLoading(false)
        })
    }

    const getPlusButton = (name) => {
        return <>
            <div className='col-12 col-md-4 mt-3 mb-2'
                style={{
                    height: '150px',
                    marginBottom: '3%',
                    maxWidth: '150px',
                    outlineColor: 'transparent'
                }}
            >
                <PlusButton
                    htmlFor={name}
                    content_color={content_color}
                    fullWidth={true}
                    className='align-items-center justify-content-center'
                    content_font_color={content_font_color}
                    disabled={isLoading}
                    button_shadow_color={button_shadow_color}
                >
                    <input
                        accept='image/png,image/jpg,image/jpeg,video/mp4,video/quicktime'
                        id={name}
                        onChange={(e) => handleGalleryChange(e, name)}
                        className='d-none'
                        type='file'
                        disabled={isLoading}
                        multiple={true}
                        name={name}
                    />
                    <i className="fas fa-plus" aria-hidden="true"></i>
                </PlusButton>
            </div>
        </>
    }

    const handleDeletePhoto = (index, name) => {
        if (name === 'preview') {
            setPreviewFile([])
        } else {
            const copyNewFiles = [...newFiles]
            const updatedFilesArray = [
                ...copyNewFiles.slice(0, index),
                ...copyNewFiles.slice(index + 1)
            ]
            if (updatedFilesArray.length === 1 && isRearrangeModeEnable === true) {
                setIsRearrangeModeEnable(false)
            }
            setNewFiles(updatedFilesArray)
        }
    }

    const uploadProgressInMb = (galleryIndex, progressNumber) => {
        const type = _.get(uploadProgress, 'type', '')
        let actualFileSize = 0
        let actualFileUploaded = '0'
        const selectedFile = type === 'original' ? newFiles[galleryIndex].selectedFile : previewFile[galleryIndex].selectedFile
        actualFileSize = (_.get(selectedFile, 'size', 0) / 1024) / 1024
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text' style={{ color: site_font_color }}>Uploading {actualFileUploaded} MB Of {actualFileSize.toFixed(2)} MB</div>
    }

    const getGalleryProgress = (index) => {
        const progress = _.get(uploadProgress, 'progress', '')
        const progressIndex = _.get(uploadProgress, 'index', '')
        const progressNumber = _.get(uploadProgress, 'progressNumber', '')
        if (progress !== '' && progressIndex === index) {
            return <span className='progress-span mt-1'>
                <div className="progress">
                    <div
                        className='progress-bar progress-bar-striped progress-bar-animated'
                        role='progressbar'
                        style={{ width: progress, backgroundColor: content_color, color: content_font_color }}
                    >{progress}</div>
                </div>
                {uploadProgressInMb(progressIndex, progressNumber)}
            </span>
        }
        return <></>
    }

    const previewContent = (name) => {
        const filesArray = name === 'preview' ? previewFile : newFiles
        return <>
            {filesArray.map((item, i) => {
                let url = 'url(' + item.renderFile + ')'
                let file = item.renderFile
                if (item.url) {
                    file = item.url
                    url = 'url(' + item.url + ')'
                }
                const fileType = _.get(item, 'selectedFile.type', item.content_type)
                const fileSrc = _.get(item, 'renderFile', item.url)

                return (
                    <ColumnDiv isUploading={isLoading} content_color={content_color} site_font_color={site_font_color} key={i} className='col-12 col-md-4 d-flex justify-content-center mt-3'>
                        {['video/quicktime', 'video/mp4', 'video'].includes(fileType) ?
                            <>
                                <CloseButton
                                    className='align-items-center close d-flex justify-content-center mr-4 mt-2'
                                    content_color={content_color}
                                    content_font_color={content_font_color}
                                    type={'video'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleDeletePhoto(i, name)
                                    }}
                                    disabled={isLoading}
                                    style={{ zIndex: '5' }}
                                    isRearrangeModeEnable={isRearrangeModeEnable}
                                />
                                <VideoDiv
                                    onClick={() => { openDialog(file, 'video') }}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragStart={() => {
                                        handleDrag(i)
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault()
                                        handleDrop(i)
                                    }}
                                    draggable={isRearrangeModeEnable && name === 'original' ? true : false}
                                >
                                    <OverLayDiv type='video' isRearrangeModeEnable={isRearrangeModeEnable} />
                                    {isIOS ?
                                        <video src={fileSrc} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                        :
                                        <video src={fileSrc} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                    }
                                    <GalleryIcon>
                                        <span className='video-button-span'>
                                            <i className='fas fa-play-circle play-icon' />
                                        </span>
                                    </GalleryIcon>
                                </VideoDiv>
                                {isRearrangeModeEnable === true && name === 'original' &&
                                    <div className='positioning text-dark d-inline'>
                                        <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none', marginTop: i === (newFiles.length - 1) ? '3rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i - 1)
                                        }}></i>{i > 0 && <br></br>}
                                        <i className='fas fa-arrow-down fa-2x' style={{ display: i === (newFiles.length - 1) && 'none', marginTop: i === 0 ? '3rem' : (i < (newFiles.length - 1) && i > 0) ? '1rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i + 1)
                                        }}></i>
                                    </div>
                                }
                                {(name === uploadProgress.type && isLoading) && getGalleryProgress(i)}
                            </>
                            :
                            <>
                                <CloseButton
                                    className='align-items-center close d-flex justify-content-center mr-3 mt-2'
                                    content_color={content_color}
                                    content_font_color={content_font_color}
                                    type={'image'}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleDeletePhoto(i, name)
                                    }}
                                    disabled={isLoading}
                                    style={{ zIndex: '5' }}
                                    isRearrangeModeEnable={isRearrangeModeEnable}
                                />
                                <DivData
                                    onClick={() => { openDialog(file, 'photo') }}
                                    imageUrl={url}
                                    draggable={isRearrangeModeEnable && name === 'original' ? true : false}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragStart={() => {
                                        handleDrag(i)
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault()
                                        handleDrop(i)
                                    }}
                                >
                                    <OverLayDiv type='image' isRearrangeModeEnable={isRearrangeModeEnable} />
                                </DivData>
                                {isRearrangeModeEnable === true && name === 'original' &&
                                    <div className='positioning text-dark d-inline'>
                                        <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none', marginTop: i === (newFiles.length - 1) ? '3rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i - 1)
                                        }}></i>{i > 0 && <br></br>}
                                        <i className='fas fa-arrow-down fa-2x' style={{ display: i === (newFiles.length - 1) && 'none', marginTop: i === 0 ? '3rem' : (i < (newFiles.length - 1) && i > 0) ? '1rem' : '2rem' }} onClick={(e) => {
                                            e.preventDefault()
                                            reArrangeImage(i, i + 1)
                                        }}></i>
                                    </div>
                                }
                                {(name === uploadProgress.type && isLoading) && getGalleryProgress(i)}
                            </>
                        }
                    </ColumnDiv>
                )
            })
            }
        </>
    }

    const handleDrag = (index) => {
        if (isRearrangeModeEnable === true) {
            setDragId(index)
        }
    }

    const handleDrop = (index) => {
        if (isRearrangeModeEnable === true && dragId !== '') {
            const copyNewFiles = [...newFiles]
            const dragFile = copyNewFiles[dragId]
            copyNewFiles.splice(dragId, 1)
            copyNewFiles.splice(index, 0, dragFile)
            setNewFiles(copyNewFiles)
            setDragId('')
        }
        setDragId('')
    }

    const openDialog = (url, type) => {
        if (isLoading === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    const blogTitle = useMemo(() => {
        if (blog !== false) {
            if (blog.description.length > 80) {
                return blog.description.slice(0, 80) + '...'
            }
            return blog.description
        }
        return ''
    }, [blog.description])

    const reArrangeImage = (dragIndex, dropIndex) => {
        let copyNewFiles = [...newFiles]
        const draggedImage = copyNewFiles[dragIndex]
        copyNewFiles[dragIndex] = copyNewFiles[dropIndex]
        copyNewFiles[dropIndex] = draggedImage
        setNewFiles(copyNewFiles)
    }
    const onChangeFeedTags = (feedTags) => {
        setFeedTagErrorText('')
        let selectedTags = []
        for (const tag of feedTags) {
            const slug = tag.value.trim().toLowerCase()
            const isNew = _.get(tag, '__isNew__', false)
            if (slug === '') {
                setFeedTagErrorText('Tag not allow empty')
            } else if (isNew) {
                const isValid = feed_tags.find(item => item.slug === slug)
                if (isValid !== undefined) {
                    setFeedTagErrorText('Tag already added')
                } else {
                    selectedTags.push(tag)
                }
            } else {
                selectedTags.push(tag)
            }
        }
        setFeedTag(selectedTags)
    }

    return (
        <WebsiteFontColor>
            {isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openDialog('', '') }}
                    type={type}
                    showWatermark={false}
                />
            }
            <div className='modal fade show' role='dialog' style={{ display: isDialogOpen ? 'none' : 'block', backgroundColor: '#00000080' }}>
                <div className='modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable' role='document'>
                    <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                        <div className='modal-header align-items-center'>
                            {blog !== false &&
                                <>
                                    <h4 className='modal-title trancated-text' data-tip={blog.description}>{blogTitle}</h4>
                                    <ReactTooltip place='top' type='info' effect='solid' />
                                </>
                            }
                            <button className='close' onClick={() => props.onClose()} style={{ color: site_font_color, opacity: 1 }} disabled={isLoading} />
                        </div>
                        <div className='modal-body'>
                            {isLoadingData === true ?
                                <div className='text-center'>
                                    <div className='spinner-border' role='status' style={{ color: site_font_color }}>
                                        <span className='sr-only'>Loading...</span>
                                    </div>
                                </div>
                                :
                                <div className='container'>
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <textarea
                                                id='description'
                                                name='description'
                                                className='form-control'
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col'>
                                            <>
                                                <label>Blur Photo/Video</label>
                                                <div className='form-group'>
                                                    <div className='from-check form-check-inline'>
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            value={true}
                                                            checked={privateBlur === true ? true : false}
                                                            onChange={() => setPrivateBlur(true)}
                                                            name='privateBlur1'
                                                            id='REVIEW_PHOTO_BLUR1'
                                                            disabled={isLoading}
                                                        />
                                                        <label className='form-check-label' htmlFor='REVIEW_PHOTO_BLUR1'>
                                                            Enable Blur
                                                        </label>
                                                    </div>
                                                    <div className='from-check form-check-inline'>
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            value={false}
                                                            checked={privateBlur === false ? true : false}
                                                            onChange={() => setPrivateBlur(false)}
                                                            name='privateBlur1'
                                                            id='REVIEW_PHOTO_NOT_BLUR1'
                                                            disabled={isLoading}
                                                        />
                                                        <label className='form-check-label' htmlFor='REVIEW_PHOTO_NOT_BLUR1'>
                                                            Disable Blur
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                            <label>Blur Caption</label>
                                            <div className='form-group'>
                                                <div className='from-check form-check-inline'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        value={true}
                                                        checked={captionBlur === true ? true : false}
                                                        onChange={() => setCaptionBlur(true)}
                                                        name='captionBlur1'
                                                        id='enableCaption1'
                                                        disabled={isLoading}
                                                    />
                                                    <label className='form-check-label' htmlFor='enableCaption1'>
                                                        Enable Blur
                                                    </label>
                                                </div>
                                                <div className='from-check form-check-inline'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        value={false}
                                                        checked={captionBlur === false ? true : false}
                                                        onChange={() => setCaptionBlur(false)}
                                                        name='captionBlur1'
                                                        id='disableCaption1'
                                                        disabled={isLoading}
                                                    />
                                                    <label className='form-check-label' htmlFor='disableCaption1'>
                                                        Disable Blur
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {blog !== false && blog.isLocked === true &&
                                        <div className='form-row'>
                                            <div className='form-group col col-md-6 col-lg-6'>
                                                <label htmlFor='amount'>Amount</label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    id='amount'
                                                    name='amount'
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    min={content_unlock_minimum_amount}
                                                    max={content_unlock_maximum_amount}
                                                    disabled={isLoading}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {feed_tags_enable && <div className='form-row'>
                                        <div className='form-group col-md-12 col-lg-6'>
                                            <label htmlFor='feed-tag'>Tag</label>
                                            <CreatableSelect
                                                name='FeedTag'
                                                className='text-dark'
                                                isMulti={true}
                                                options={props.blog.tagList}
                                                value={feedTags}
                                                onChange={(tags) => onChangeFeedTags(tags)}
                                                noOptionsMessage={() => 'No Tags Available'}
                                                isDisabled={isLoading}
                                            />
                                            {feedTagErrorText !== '' && <div className='invalid-feedback d-block'>{feedTagErrorText}</div>}
                                        </div>
                                    </div>}
                                    <div style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                                        <DropZoneSection content_color={content_color} site_font_color={site_font_color}>
                                            <h4 style={{ color: site_font_color, marginBottom: '0%' }}>Add Media</h4>
                                            <div className='row justify-content-center'>
                                                {previewContent('original')}
                                                {isRearrangeModeEnable === false && <>
                                                    {getPlusButton('original')}
                                                </>}
                                            </div>
                                            {newFiles.length > 1 &&
                                                <ReorderButton content_color={content_color} site_font_color={site_font_color} className='mt-3 ml-3 mb-3'>
                                                    <div className="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="REORDER_MODE"
                                                            name='ReorderMode'
                                                            onChange={() => {
                                                                setIsRearrangeModeEnable(!isRearrangeModeEnable)
                                                            }}
                                                            checked={isRearrangeModeEnable}
                                                            disabled={isLoading}
                                                        />
                                                        <label className="custom-control-label" htmlFor="REORDER_MODE">Reorder</label>
                                                    </div>
                                                </ReorderButton>
                                            }
                                        </DropZoneSection>
                                        {errorPreviewFile.length > 0 && errorFiles.length > 0 &&
                                            <hr className='my-2' />
                                        }
                                        {errorPreviewFile.length > 0 &&
                                            <DropZoneSection content_color={content_color} className='my-3'>
                                                <div className='text-center'>
                                                    <h4 style={{ color: site_font_color, marginBottom: '0%' }}>Preview</h4>
                                                    <div className='row justify-content-center'>
                                                        {previewFile.length > 0 ?
                                                            <>
                                                                {previewContent('preview')}
                                                            </>
                                                            :
                                                            <>
                                                                {getPlusButton('preview')}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </DropZoneSection>
                                        }
                                        <label className='d-flex justify-content-center mt-2' style={{ color: site_font_color }}><span className='text-danger' style={{ fontSize: '24px' }}>*</span>{errorText}</label>
                                        <div className='row justify-content-center my-3'>
                                            {errorFiles.map((file, index) => {
                                                return <div key={index} className='col-md-4 col-12'>
                                                    {['video'].includes(file.content_type) ?
                                                        <VideoDiv
                                                            onClick={() => { openDialog(file.url, 'video') }}
                                                        >
                                                            {isIOS ?
                                                                <video src={file.url} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black', border: '1px solid red' }} />
                                                                :
                                                                <video src={file.url} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black', border: '1px solid red' }} />
                                                            }
                                                            <GalleryIcon>
                                                                <span className='video-button-span'>
                                                                    <i className='fas fa-play-circle play-icon' />
                                                                </span>
                                                            </GalleryIcon>
                                                        </VideoDiv>
                                                        :
                                                        <div
                                                            onClick={() => { openDialog(file.url, 'photo') }}
                                                        >
                                                            <img src={file.url} style={{ border: '1px solid red' }} alt='error-content' />
                                                        </div>
                                                    }
                                                </div>
                                            })}
                                            {errorPreviewFile.map((file, index) => {
                                                return <div key={index} className='col-md-4 col-12'>
                                                    {['video'].includes(file.content_type) ?
                                                        <VideoDiv
                                                            onClick={() => { openDialog(file.url, 'video') }}
                                                        >
                                                            {isIOS ?
                                                                <video src={file.url} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black', border: '1px solid red' }} />
                                                                :
                                                                <video src={file.url} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black', border: '1px solid red' }} />
                                                            }
                                                            <GalleryIcon>
                                                                <span className='video-button-span'>
                                                                    <i className='fas fa-play-circle play-icon' />
                                                                </span>
                                                            </GalleryIcon>
                                                        </VideoDiv>
                                                        :
                                                        <div
                                                            onClick={() => { openDialog(file.url, 'photo') }}
                                                        >
                                                            <img src={file.url} style={{ border: '1px solid red' }} alt='error-content' />
                                                        </div>
                                                    }
                                                    <label>Preview</label>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                    <button className='btn btn-block' disabled={isLoading}
                                        style={{
                                            backgroundColor: content_color,
                                            color: content_font_color,
                                            boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                        }}
                                        onClick={handlePostGallerySubmit}>
                                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                        Exclude error content and post
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </WebsiteFontColor >
    )
}

ReviewBlog.propTypes = {
    auth: PropTypes.object.isRequired,
    blog: PropTypes.object.isRequired,
    blog_id: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    getBlogDataById: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    postReviewedBlog: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog
})

export default connect(
    mapStateToProps, { getBlogDataById, setSweetAlert, postReviewedBlog }
)(withRouter((ReviewBlog)))
