import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

const PreviousMedia = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    left: 0;
    cursor: pointer;
    z-index: 1;
    padding: 12px 5px;
`

const NextMedia = styled.div`
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    cursor: pointer;
    z-index: 1;
    padding: 12px 5px;
`

const Image = styled.img`
    cursor: ${props => props.isLocked ? 'inherit' : 'pointer'};
    height: calc(60vh - 70px);
    object-fit: cover;
    width: 100%;

    @media (min-width: ${size.mobileS}) and (max-width: ${size.tablet}) {
        height: auto;
        max-height: auto;
    }

    @media (min-width: ${size.tablet}) and (max-width: ${size.laptop}) {
        height: auto;
        max-height: auto;
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(48.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const BlogMedia = (props) => {
    const { media, setCurrentMediaIndex, isLocked, contentLeftForProcessing } = props
    const [index, setIndex] = useState(0)
    const showNextAndPreviousButton = contentLeftForProcessing > 0 ? false : (isLocked === false && media.length > 1) ? true : false

    const previousMedia = () => {
        let currentIndex = index - 1
        if (index <= 0) {
            currentIndex = media.length - 1
            setCurrentMediaIndex(currentIndex)
            return setIndex(currentIndex)
        }
        setCurrentMediaIndex(currentIndex)
        setIndex(currentIndex)
    }

    const nextMedia = () => {
        let currentIndex = index + 1
        if (index >= (media.length - 1)) {
            setCurrentMediaIndex(0)
            return setIndex(0)
        }
        setCurrentMediaIndex(currentIndex)
        setIndex(currentIndex)
    }
    let url = ''
    if (Boolean(isLocked) === true) {
        url = media[index].blur_url
    } else {
        if (media[index].content_type === 'video') {
            url = media[index].thumbnail_url
        } else {
            url = media[index].url
        }
    }
    return <div style={{ position: 'relative' }}>
        {showNextAndPreviousButton && <PreviousMedia onClick={previousMedia}>
            <i className='fas fa-chevron-left fa-2x'></i>
        </PreviousMedia>}
        <div className='text-center'>
            <div style={{ position: 'relative' }}
                onClick={() => {
                    if (Boolean(isLocked) !== true && Number(contentLeftForProcessing) === 0) {
                        props.openDlgBlog(props.i, index)
                    }
                }}>
                {Number(contentLeftForProcessing) > 0 || media === undefined ?
                    <span className='mb-2' style={{ height: '150px', display: 'inline-block' }}>This post is under process. It will be visible to users when processing is completed.</span>
                    :
                    <>
                        <Image
                            draggable={false}
                            src={url}
                            alt=''
                            isLocked={props.isLocked}
                        />
                    </>
                }
                {Number(contentLeftForProcessing) === 0 && (Boolean(isLocked) === false && media[index].content_type === 'video') &&
                    <GalleryIcon>
                        <span className='video-button-span'>
                            <i className='fas fa-play-circle play-icon' />
                        </span>
                    </GalleryIcon>
                }
            </div>
        </div>
        {showNextAndPreviousButton && <NextMedia onClick={nextMedia}>
            <i className='fas fa-chevron-right fa-2x'></i>
        </NextMedia>}
    </div>
}

BlogMedia.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.array,
    isLocked: PropTypes.bool.isRequired,
    i: PropTypes.number.isRequired,
    openDlgBlog: PropTypes.func,
    setCurrentMediaIndex: PropTypes.func.isRequired,
    isPreviewEnable: PropTypes.bool,
    is_published: PropTypes.bool,
    contentLeftForProcessing: PropTypes.number
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(
    mapStateToProps
)(BlogMedia)
