export const SUBSCRIPTION_STATUS_ACTIVE = '2'
export const SUBSCRIPTION_STATUS_ACTIVE_CANCELLED = '1'
export const FILE_SIZE_LIMIT_IN_BYTE = 5000000000
export const ROLE_ADMIN = 'admin'
export const ROLE_MODEL = 'model'
export const ROLE_CONTENT_MANAGER = 'content_manager'
export const ROLE_USER = 'user'
export const ROLE_GUEST = 'guest'
export const ROLE_SUPPORT = 'support'
export const ROLE_SUB_ADMIN = 'sub_admin'
export const ROLE_PROXY_USER = 'proxy_user'
export const ROLE_LIVE_STREAM_MANAGER = 'live_stream_manager'
