import {
    GALLERY_ADD_FILE,
    GALLERY_REMOVE_FILE,
    GALLERY_RESET,
    UPDATE_GALLERY_PROGRESS,
    UPDATE_GALLERY_INDEX,
    GALLERY_ADD_FILE_IN_PREVIEW,
    GALLERY_REMOVE_FILE_IN_PREVIEW,
    PREVIEW_GALLERY_RESET,
    SET_IS_PREVIEW_MODE_ENABLE
} from '../actions/types'

const initialState = {
    files: [],
    previewFiles: [],
    uploadProgress: {
        progress: '',
        index: '',
        progressNumber: '',
        type: ''
    },
    isPreviewModeEnable: false
}

export default function (state = initialState, action) {
    let updatedGalleryProgress = action.payload
    switch (action.type) {
        case GALLERY_ADD_FILE:
            return {
                ...state,
                files: [
                    ...state.files,
                    action.payload
                ]
            }
        case GALLERY_REMOVE_FILE:
            return {
                ...state,
                files: [
                    ...state.files.slice(0, action.payload),
                    ...state.files.slice(action.payload + 1)
                ]
            }
        case GALLERY_RESET:
            return {
                ...state,
                files: [],
                uploadProgress: {
                    progress: '',
                    index: '',
                    progressNumber: ''
                }
            }
        case UPDATE_GALLERY_PROGRESS:
            return {
                ...state,
                uploadProgress: updatedGalleryProgress
            }
        case UPDATE_GALLERY_INDEX:
            return {
                ...state,
                files: updatedGalleryProgress
            }
        case GALLERY_ADD_FILE_IN_PREVIEW:
            return {
                ...state,
                previewFiles: [
                    ...state.previewFiles,
                    action.payload
                ]
            }
        case GALLERY_REMOVE_FILE_IN_PREVIEW:
            return {
                ...state,
                previewFiles: [
                    ...state.previewFiles.slice(0, action.payload),
                    ...state.previewFiles.slice(action.payload + 1)
                ]
            }
        case PREVIEW_GALLERY_RESET:
            return {
                ...state,
                previewFiles: [],
                uploadProgress: {
                    progress: '',
                    index: '',
                    progressNumber: ''
                }
            }
        case SET_IS_PREVIEW_MODE_ENABLE:
            return {
                ...state,
                isPreviewModeEnable: !state.isPreviewModeEnable
            }
        default:
            return state
    }
}
