import {
    SET_CURRENT_USER,
    USER_LOADING,
    LOAD_APP_SETTINGS,
    PROFILE_PHOTO_UPLOADING,
    SET_IS_PROFILE_READY,
    PHOTO_ID_UPLOADING,
    PHOTO_ID_UPLOADED,
    OPEN_PHOTO_ID_MODAL,
    CLOSE_PHOTO_ID_MODAL,
    SET_UNREAD_COUNT,
    LOAD_THEME_3_SETTINGS,
    ADD_PAY_PER_MESSAGE_CREDIT,
    REMOVE_PAY_PER_MESSAGE_CREDIT,
    UPDATE_USER_OPT_IN_DETAIL,
    UPDATE_USER_OPT_IN_EMAIL_STATUS,
    UPDATE_USER_SUBSCRIPTION_STATUS,
    HIDE_MIGRATION_SUBSCRIPTION_POP,
    ADD_CUSTOM_MENU_LINKS,
    SET_USER_SUBSCRIPTION_TO_0,
    UPDATE_USER_REBILL_FAILED_STATUS,
    SET_IS_HELP_ENABLE,
    SET_IS_PUBLIC_POST_ENABLE,
    SET_IS_BLOG_COMMENTS_ENABLE,
    SET_DEFAULT_PAYMENT_METHOD,
    SET_WALLET_AMOUNT,
    UPDATE_FEED_TAG_SETTINGS,
    RESET_REBILL_NOTIFICATION_POPUP,
    UPDATE_CHAT_LIST_OPTIONS,
    SET_FEED_TAG_LIST,
    UPDATE_USER_LIST,
    SET_SHOW_MEDIA_COUNT_IN_EXCLUSIVE_CONTENT,
    RESET_UNREAD_COUNT,
    LOGOUT_USER_LOADER,
    UPDATE_USER_SUBSCRIPTION_PAYMENT_GATEWAY,
    UPDATE_LINK_PAGE_DATA,
    UPDATE_UNREAD_COUNT,
    IS_ENABLED_UNIVERSAL_LOGIN_SETTING,
    UPDATE_USER_EMAIL_AFTER_VERIFIED,
    SET_RESET_PASSWORD_PAGE_LOADER,
    SET_IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED,
    UPDATE_TIPS_PAGE_DATA,
    UPDATE_THEME_2_BANNER_SETTINGS,
    UPDATE_THEME_2_BANNER_SETTINGS_AFTER_FILE_UPLOAD,
    UPDATE_APP_SETTING_SINGLE_KEY
} from '../actions/types'

import isEmpty from 'is-empty'
import _ from 'lodash'

const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    accessToken: '',
    appSettings: {},
    bannerImages: [],
    isReady: false,
    profilePhotoUploading: false,
    isAdmin: false,
    isModel: false,
    isSuperAdmin: false,
    isContentManager: false,
    isSupport: false,
    isBlocked: false,
    isProfileReady: false,
    isPhotoIdUploading: false,
    isPhotoIdModalOpen: false,
    theme3Settings: {},
    counts: {},
    isSubAdmin: false,
    customMenuLinks: [],
    linkPageData: [],
    resetPasswordPageLoader: true
}


export default function (state = initialState, action) {
    const user = state.user
    const appSetting = state.appSettings
    const role = _.get(action.payload, 'role', false)
    const isAuthenticated = !isEmpty(action.payload)
    const isContentManager = (role === 'content_manager')
    const isModel = (role === 'model')
    const isSuperAdmin = (role === 'admin')
    const isSubAdmin = (role === 'sub_admin')
    const isSupport = (role === 'support')
    const isAdmin = _.get(action.payload, 'isAdmin', false)
    const count = state.counts
    let unreadCount
    let bannerImages = []
    const userUnreadCount = state.counts

    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: isAuthenticated,
                user: action.payload,
                profilePhotoUploading: false,
                isAdmin: isAdmin,
                isModel: isModel,
                isContentManager: isContentManager,
                isSuperAdmin: isSuperAdmin,
                isSupport: isSupport,
                isSubAdmin: isSubAdmin
            }
        case USER_LOADING:
            return {
                ...state,
                loading: true
            }
        case LOAD_APP_SETTINGS:
            document.title = action.payload.appSettings.model_name
            if (action.payload.appSettings.theme_2_banner_images_url) {
                bannerImages = action.payload.appSettings.theme_2_banner_images_url.split(',')
            }
            return {
                ...state,
                isReady: true,
                isBlocked: action.payload.blocked,
                appSettings: action.payload.appSettings,
                bannerImages: bannerImages
            }
        case LOAD_THEME_3_SETTINGS:
            return {
                ...state,
                theme3Settings: action.payload.data
            }
        case PROFILE_PHOTO_UPLOADING:
            return {
                ...state,
                profilePhotoUploading: true
            }
        case PHOTO_ID_UPLOADING:
            return {
                ...state,
                isPhotoIdUploading: true
            }
        case PHOTO_ID_UPLOADED:
            return {
                ...state,
                isPhotoIdUploading: false
            }
        case OPEN_PHOTO_ID_MODAL:
            return {
                ...state,
                isPhotoIdModalOpen: true
            }
        case CLOSE_PHOTO_ID_MODAL:
            return {
                ...state,
                isPhotoIdModalOpen: false
            }
        case SET_IS_PROFILE_READY:
            return {
                ...state,
                isProfileReady: true
            }
        case SET_UNREAD_COUNT:
            return {
                ...state,
                counts: action.payload
            }
        case ADD_PAY_PER_MESSAGE_CREDIT:
            user.payPerMessageCredit = user.payPerMessageCredit + 1
            return {
                ...state,
                user: user
            }
        case REMOVE_PAY_PER_MESSAGE_CREDIT:
            user.payPerMessageCredit = user.payPerMessageCredit > 0 ? user.payPerMessageCredit - 1 : 0
            return {
                ...state,
                user: user
            }
        case UPDATE_USER_OPT_IN_DETAIL:
            if (user.shouldPresentEmailOptIn !== undefined) {
                user.shouldPresentEmailOptIn = false
                user.emailOptInStatus = 'no_thanks'
                user.emailEventStatus = 'no_thanks'
                user.chat_email_notifications = false
                user.ChatEmailNotifications = false
            }
            return {
                ...state,
                user: user
            }
        case UPDATE_USER_OPT_IN_EMAIL_STATUS:
            user.emailOptInStatus = 'link_sent'
            return {
                ...state,
                user: user
            }
        case UPDATE_USER_SUBSCRIPTION_STATUS:
            user.ccbillSubscriptionStatus = '2'
            user.payment.membership = true
            user.isRebillFailed = false
            return {
                ...state,
                user: user
            }
        case HIDE_MIGRATION_SUBSCRIPTION_POP:
            user.showMigrationPopup = false
            return {
                ...state,
                user: user
            }
        case ADD_CUSTOM_MENU_LINKS:
            return {
                ...state,
                customMenuLinks: action.payload
            }
        case SET_USER_SUBSCRIPTION_TO_0:
            user.ccbillSubscriptionStatus = '0'
            user.payment.membership = false
            return {
                ...state,
                user: user
            }
        case UPDATE_USER_REBILL_FAILED_STATUS:
            user.isRebillFailed = false
            return {
                ...state,
                user: user
            }
        case SET_IS_HELP_ENABLE:
            appSetting.influencer_help_enabled = action.payload.influencer_help_enabled
            return {
                ...state,
                appSettings: appSetting
            }
        case SET_IS_PUBLIC_POST_ENABLE:
            appSetting.enable_public_post = action.payload.enable_public_post
            return {
                ...state,
                appSettings: appSetting
            }
        case SET_IS_BLOG_COMMENTS_ENABLE:
            appSetting.is_feed_comment_enabled = action.payload.is_feed_comment_enabled
            appSetting.is_feed_like_enabled = action.payload.is_feed_like_enabled
            appSetting.comment_visibility = action.payload.comment_visibility
            appSetting.like_visibility = action.payload.comment_visibility
            return {
                ...state,
                appSettings: appSetting
            }
        case SET_DEFAULT_PAYMENT_METHOD:
            user.default_payment_method = action.payload.payment_method
            return {
                ...state,
                user
            }
        case SET_WALLET_AMOUNT:
            user.wallet_amount = action.payload
            return {
                ...state,
                user
            }
        case UPDATE_FEED_TAG_SETTINGS:
            appSetting.feed_tags_enable = action.payload.feed_tags_enable
            appSetting.feed_tag_filter_button_text = action.payload.feed_tag_filter_button_text
            return {
                ...state,
                appSettings: appSetting
            }
        case LOGOUT_USER_LOADER:
            user.logout_user_loader = action.payload
            return {
                ...state,
                user
            }
        case RESET_REBILL_NOTIFICATION_POPUP:
            user.is_notification_enable = false
            return {
                ...state,
                user: user
            }
        case UPDATE_CHAT_LIST_OPTIONS:
            appSetting.is_enable_mute_unmute_mass_message = action.payload.is_enable_mute_unmute_mass_message
            appSetting.is_enable_user_block_access = action.payload.is_enable_user_block_access
            appSetting.is_enable_sort_by_subscription_expire = action.payload.is_enable_sort_by_subscription_expire
            appSetting.is_enable_mark_as_unread = action.payload.is_enable_mark_as_unread
            return {
                ...state,
                appSettings: appSetting
            }
        case UPDATE_USER_LIST:
            appSetting.is_enable_user_list = action.payload.is_enable_user_list
            return {
                ...state,
                appSettings: appSetting
            }
        case SET_SHOW_MEDIA_COUNT_IN_EXCLUSIVE_CONTENT:
            appSetting.show_media_count_in_exclusive_content = action.payload
            return {
                ...state,
                appSettings: appSetting
            }
        case SET_FEED_TAG_LIST:
            appSetting.feed_tags = action.payload
            return {
                ...state,
                appSettings: appSetting
            }
        case RESET_UNREAD_COUNT:
            unreadCount = count.userUnreadMessage = action.payload
            return {
                ...state,
                counts: unreadCount
            }
        case UPDATE_USER_SUBSCRIPTION_PAYMENT_GATEWAY:
            user.payment_gateway = action.payload
            return {
                ...state,
                user
            }
        case UPDATE_LINK_PAGE_DATA:
            return {
                ...state,
                linkPageData: action.payload
            }
        case UPDATE_UNREAD_COUNT:
            userUnreadCount.userUnreadMessage += action.payload
            return {
                ...state,
                counts: userUnreadCount
            }
        case IS_ENABLED_UNIVERSAL_LOGIN_SETTING:
            appSetting.is_universal_login_enabled = action.payload
            return {
                ...state,
                appSettings: appSetting
            }
        case UPDATE_USER_EMAIL_AFTER_VERIFIED:
            user.emailLog = action.payload.emailLog
            user.email = action.payload.newEmail
            return {
                ...state,
                user: user
            }
        case SET_RESET_PASSWORD_PAGE_LOADER:
            return {
                ...state,
                resetPasswordPageLoader: action.payload
            }
        case SET_IS_PAGINATION_ON_EXCLUSIVE_CONTENT_ENABLED:
            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    is_pagination_on_exclusive_content_enabled: action.payload
                }
            }
        case UPDATE_TIPS_PAGE_DATA:
            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    tips_page_send_tip_button_text: action.payload.tips_page_send_tip_button_text,
                    text_tips_banner: action.payload.text_tips_banner,
                    tips_page_header_text: action.payload.tips_page_header_text,
                    tip_amounts: action.payload.tip_amounts,
                    tips_button_border_color_and_size: action.payload.tips_button_border_color_and_size
                }
            }
        case UPDATE_THEME_2_BANNER_SETTINGS:

            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    ...action.payload
                }
            }

        case UPDATE_THEME_2_BANNER_SETTINGS_AFTER_FILE_UPLOAD:
            const { key, value } = action.payload

            const urlSegmentToUpdate = value.split('/').pop()

            const existingUrlSegments = state.appSettings[action.payload.key].split('/')
            existingUrlSegments.pop()
            existingUrlSegments.push(urlSegmentToUpdate)

            const updatedUrl = existingUrlSegments.join('/')

            const property = {}
            property[key] = updatedUrl

            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    ...property
                }
            }

        case UPDATE_APP_SETTING_SINGLE_KEY:
            appSetting[action.payload.key] = action.payload.value
            return {
                ...state,
                appSettings: appSetting
            }
        default:
            return state
    }
}
