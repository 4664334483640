// @flow

import * as React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PageWrapper from './Layouts/PageWrapper'
import Theme2BlogPage from './theme-2/Theme2BlogPage'
import LandingPage from './landing-page/LandingPage'
import WebsiteFontColor from './Layouts/WebsiteFontColor'
import { Link } from 'react-router-dom'
import socket from '../utils/socket'
import styled from 'styled-components'
import PromotionModal from './Layouts/PromotionModal'
import _ from 'lodash'
import { checkPromotion } from '../actions/PromotionActions'
import OptInEmail from './opt-in-email/OptInEmail'
import { setHelpDataAsRead, influencerPopupHide } from './../actions/appSettingsActions'
import { setUserHelpDataAsRead, userInfluencerPopupHide } from './../actions/userInfluencerHelpAction'
import ModalPopUp from './Layouts/ModalPopUp'
import HTMLDiv from './Layouts/htmlDivStyledComponent'
import MaintenancePage from './maintenance/Maintenance'
import { getCloudFrontAssetsUrl } from '../utils/assets'
import { getAllPromotionOffers } from '../actions/PromotionActions'

const NavLink = styled(Link)`
    font-size: medium !important;
    width: fit-content;
    border-radius: 5px !important;
    color: ${props => props.color} !important;
    background-color: ${props => props.backgroundcolor};

    :hover {
        color: ${props => props.color};
    }
`

class Home extends React.Component {
    constructor() {
        super()
        this.state = {
            onlineUserCount: 0,
            onlineAllUsersCount: 0,
            helperModelOpen: false,
            showPopup: false,
            promotionModelOpen: false,
            promotionButtonText: 'SET PROMOTION',
            helperModelOpenForPromotion: false,
            showInfluencerHelpPopup: false,
            popupData: null
        }

        this.loadSocketEvent = this.loadSocketEvent.bind(this)
        this.setHelperModelOpen = this.setHelperModelOpen.bind(this)
        this.setHelperModelOpenForPromotion = this.setHelperModelOpenForPromotion.bind(this)
        this.setPromotionModelOpen = this.setPromotionModelOpen.bind(this)
        this.handleHelperModelClose = this.handleHelperModelClose.bind(this)
        this.handleHelperModelCloseForPromotion = this.handleHelperModelCloseForPromotion.bind(this)
        this.handlePromotionModelClose = this.handlePromotionModelClose.bind(this)
        this.onStartGoLive = this.onStartGoLive.bind(this)
        this.onClose = this.onClose.bind(this)
        this.setUrl = this.setUrl.bind(this)
        this.setPromotionButtonTextAfterSave = this.setPromotionButtonTextAfterSave.bind(this)
    }

    componentDidMount() {
        this.loadSocketEvent()
        socket.emit('CHECK_ONLINE_USER')
        socket.emit('CHECK_ONLINE_EVERY_USER')
        if (typeof this.props.auth.user._id !== 'undefined' && this.props.auth.user.isAdmin === false && this.props.auth.user.role !== 'proxy_user') {
            socket.emit('USER_ONLINE', this.props.auth.user._id)
        }
        const { enable_promotion } = this.props.auth.appSettings
        const { role } = this.props.auth.user

        if (enable_promotion && (role === 'admin' || role === 'model' || role === 'sub_admin' || role === 'content_manager')) {
            this.props.checkPromotion((res) => {
                this.setState({
                    promotionButtonText: res.data.promotion_button_text
                })
            })
        }
        if (this.props.location.search === '?offers' || this.props.location.search === '?feed') {
            return this.props.history.push('/')
        }
    }

    setHelperModelOpen() {
        document.querySelector('body').style.overflow = 'hidden'
        this.setState({ helperModelOpen: true })
    }

    setHelperModelOpenForPromotion() {
        document.querySelector('body').style.overflow = 'hidden'
        this.setState({ helperModelOpenForPromotion: true })
    }

    setPromotionModelOpen() {
        document.querySelector('body').style.overflow = 'hidden'
        this.setState({ promotionModelOpen: true })
    }

    handlePromotionModelClose() {
        document.querySelector('body').style.overflow = 'visible'
        this.setState({ promotionModelOpen: false })
    }

    handleHelperModelClose() {
        document.querySelector('body').style.overflow = 'visible'
        this.setState({ helperModelOpen: false })
    }

    handleHelperModelCloseForPromotion() {
        document.querySelector('body').style.overflow = 'visible'
        this.setState({ helperModelOpenForPromotion: false })
    }

    loadSocketEvent() {
        socket.on('ONLINE_MODEL', (res) => {
            this.setState({ onlineUserCount: res })
        })
        socket.on('ALL_USERS_COUNT', (res) => {
            this.setState({ onlineAllUsersCount: res })
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.chat.userProfileInfo[nextProps.auth.appSettings.model_id] !== undefined) {
            let favicon = document.getElementById('favicon')
            favicon.href = (nextProps.chat.userProfileInfo[nextProps.auth.appSettings.model_id].profile === '' ? getCloudFrontAssetsUrl('faces/avatar.png') :
                nextProps.chat.userProfileInfo[nextProps.auth.appSettings.model_id].profile)
        }

        if (this.props.auth.user.isAdmin === true) {
            if (nextProps.influencerHelp.popup_data !== null && nextProps.influencerHelp.show_popup === true) {
                this.setState({ popupData: nextProps.influencerHelp.popup_data, showInfluencerHelpPopup: true })
            }
        }
        if (this.props.auth.user.isAdmin === false) {
            if (nextProps.userInfluencerHelp.popup_data !== null && nextProps.userInfluencerHelp.show_popup === true) {
                this.setState({ popupData: nextProps.userInfluencerHelp.popup_data, showInfluencerHelpPopup: true })
            }
        }
    }

    onStartGoLive() {
        this.setState({ showPopup: true })
    }

    onClose() {
        document.querySelector('body').style.overflow = 'visible'
        this.setState({ showInfluencerHelpPopup: false })
        if (this.props.auth.user.isAdmin === true && this.props.auth.user.isAdmin !== undefined) {
            this.props.setHelpDataAsRead(this.props.influencerHelp.popup_data._id)
            this.props.influencerPopupHide()
        } else {
            if (this.props.auth.user.role === 'proxy_user') {
                this.props.userInfluencerPopupHide()
                return
            }
            this.props.setUserHelpDataAsRead(this.props.userInfluencerHelp.popup_data._id)
            this.props.userInfluencerPopupHide()
        }
    }

    setUrl() {
        if (this.props.auth.user.isAdmin === true) {
            return `/help/home/${this.props.influencerHelp.popup_data._id}`
        } else {
            return `/user-help/home/${this.props.userInfluencerHelp.popup_data._id}`
        }
    }

    setPromotionButtonTextAfterSave() {
        const { enable_promotion } = this.props.auth.appSettings
        const { role } = this.props.auth.user

        if (enable_promotion && (role === 'admin' || role === 'model' || role === 'sub_admin' || role === 'content_manager')) {
            this.props.checkPromotion((res) => {
                this.setState({
                    promotionButtonText: res.data.promotion_button_text
                })
            })
        }
    }

    render() {
        const { is_theme_3_enabled, card_background_color, content_color, content_font_color, site_font_color, maintenance_mode } = this.props.auth.appSettings
        const shouldPresentEmailOptIn = _.get(this.props.auth.user, 'shouldPresentEmailOptIn', false)
        const emailOptInStatus = _.get(this.props.auth.user, 'emailOptInStatus', false)
        const emailEventType = _.get(this.props.auth.user, 'emailEventType', 'none')
        const emailEventStatus = _.get(this.props.auth.user, 'emailEventStatus', false)
        const isAdmin = _.get(this.props.auth.user, 'isAdmin', false)
        const is_allow_web_notification = _.get(this.props.auth.user, 'is_allow_web_notification', false)
        let subscriptionDate = _.get(this.props.auth.user, 'subscription_detail.created_at', false)
        subscriptionDate = subscriptionDate ? new Date(subscriptionDate) : new Date()
        subscriptionDate.setDate(subscriptionDate.getDate() + 5)
        const showEmailNotificationPrompt = localStorage.getItem('showEmailNotificationPrompt')
        const { setNotificationPrompt } = this.props.pushNotification
        const { showAlert } = this.props.sweetAlert

        if (maintenance_mode === true && isAdmin === false) {
            return (
                <WebsiteFontColor>
                    <MaintenancePage />
                </WebsiteFontColor>
            )
        }

        if (is_theme_3_enabled === true && this.props.auth.isAuthenticated === false) {
            return <LandingPage></LandingPage>
        }

        if (this.props.auth.isSupport === true) {
            return <PageWrapper></PageWrapper>
        }

        return (
            <div>
                <PageWrapper>
                    {showAlert === false && (((subscriptionDate < new Date() && is_allow_web_notification === false) || showEmailNotificationPrompt === 'true') && ((shouldPresentEmailOptIn === true && ['opt_out', 'pending'].includes(emailOptInStatus)) || (emailEventType !== 'none' && emailEventStatus === 'pending'))) ?
                        <OptInEmail />
                        : null
                    }
                    {setNotificationPrompt === false && this.state.popupData !== null && this.state.showInfluencerHelpPopup === true &&
                        <ModalPopUp classes='modal-lg' popup_title={this.state.popupData.title} handleClose={() => {
                            this.onClose()
                        }}>
                            <div className='row'>
                                <HTMLDiv
                                    dangerouslySetInnerHTML={{ __html: this.state.popupData.popup_intro }}
                                ></HTMLDiv>
                            </div>
                            <div className='text-center'>
                                <NavLink backgroundcolor={content_color} color={content_font_color} className='btn px-4 py-2 mb-2' to={this.setUrl}>
                                    View Detail
                                </NavLink>
                            </div>
                        </ModalPopUp>
                    }
                    <Theme2BlogPage onlineUserCount={this.state.onlineUserCount} onlineAllUsersCount={this.state.onlineAllUsersCount} setHelperModelOpenForPromotion={this.setHelperModelOpenForPromotion} setHelperModelOpen={this.setHelperModelOpen} setPromotionModelOpen={this.setPromotionModelOpen} onStartGoLive={this.onStartGoLive} promotionButtonText={this.state.promotionButtonText} />
                    {this.state.showPopup && <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered' role='document' style={{ justifyContent: 'center' }}>
                            <div className='modal-content' style={{ backgroundColor: 'white', color: 'black', width: '275px' }}>
                                <div className='modal-body'>
                                    <div className='container'>
                                        <div className='row text-center mb-2'>
                                            <div className='col'>
                                                You are about to start a Go Live Session with all online subscribers
                                            </div>
                                        </div>
                                        <div className='row p-2 text-center'>
                                            <div className='col-md-6 col-12'>
                                                <Link to='/group-live-stream' style={{ textDecoration: 'none' }}>
                                                    <button
                                                        className='btn btn-block pay-btn px-1'
                                                        style={{ backgroundColor: content_color, color: content_font_color, borderRadius: '3px' }}
                                                    >
                                                        Continue
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className='col-md-6 col-12 mt-2 mt-md-0' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <button
                                                    className='btn btn-block pay-btn'
                                                    style={{ backgroundColor: content_color, color: content_font_color, borderRadius: '3px' }}
                                                    onClick={() => this.setState({ showPopup: false })}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </PageWrapper>
                {this.state.helperModelOpen &&
                    <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                            <div className='modal-content' style={{ color: site_font_color }}>
                                <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                    <h5 className='modal-title'>Go Live Stream</h5>
                                    <button className='close' onClick={this.handleHelperModelClose} style={{ color: site_font_color, opacity: 1 }} />
                                </div>
                                <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                    <b>GO LIVE NOW</b>
                                    <p>Go Live will start an online video session with all subscribers who are currently online.  The bubble on the top-right of the GO LIVE NOW button indicates how many users are currently on the website.</p>
                                    <p>Once you are live, all online subscribers will be automatically added to the session and you will see an indication of how many subscribers are currently watching you. They can see and hear you and can type messages and you will be able to see both the messages and who sent them. Subscribers can only see their own messages.</p>
                                    <p>Subscribers can tip you and all subscribers will see all tips. They will have the option of sending the tips anonymously, so other subscribers will not see their names, although you will see their names. You should have fun and encourage subscribers to send you tips. For example, you might offer to answer questions or take requests from anyone who sends a tip of a certain amount. Perhaps you can offer to have a private chat with the subscribers who sends the largest tip or send special private content. It&apos;s up to you, so have fun with it.</p>
                                    <p>You can end the session at any time by clicking the X at the top right of the Go Live session screen. Subscribers can leave the session and re-join. When they leave, they will see a ribbon at the top of every screen with a button to make it easy to re-join.</p>
                                    <b>MARKETING THE SESSION AND RUNNING PROMOTIONS</b>
                                    <p>Many of our influencers market the session in advance by sending a Mass Message announcing the session along with the day and time. Be sure to mention the time zone. You should also promote the session on your social media accounts a day or two before the session and just before you start the session.</p>
                                    <p>You can consider running a new subscriber promotion during the session where you either discount the first month to a lower price, say $1.99, or make the subscription free for the first month. We&apos;re happy to help you with this.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.helperModelOpenForPromotion &&
                    <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                            <div className='modal-content' style={{ color: site_font_color }}>
                                <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                    <h5 className='modal-title'>{this.state.promotionButtonText}</h5>
                                    <button className='close' onClick={this.handleHelperModelCloseForPromotion} style={{ color: site_font_color, opacity: 1 }} />
                                </div>
                                <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                    <p>The promotion engine allows you to run promotions for both the first month of the subscription price and for the price of locked content. You’ll see a new “SET PROMOTION” button under the content posting form on the main page. There are two tabs: New Subscriber Promotion and Locked Content Promotion.</p>
                                    <b>NEW SUBSCRIBER PROMOTIONS</b>
                                    <p>This option allows you to discount the first month for new subscribers. You can set the first month at a price that is less than what it’s currently set at. For example, if your current first month is $9.99, you’ll be able to discount the first month from $8.99 all the way down to free. If on the other hand your first month is set to $4.99, your promotion price will start at $3.99.</p>
                                    <p>You can set a time-frame for the promotion starting at 1 hour all the way up to many days. Users who visit your site will see a banner across the top of the screen announcing the promotion along with a ticking clock to make it clear that time is running out and giving them a sense of urgency.</p>
                                    <b>LOCKED CONTENT PROMOTIONS</b>
                                    <p>Here you can discount the price of your locked content. You can choose to reduce the price on all locked content, only exclusive/feed content or only mass messages. You can choose any discount % along with the time-frame that you want to run the promotion.</p>
                                    <b>PROMOTION RIBBON AND MESSAGING</b>
                                    <p>For both options we display a ribbon across the top of the screen that shows the sale info and displays a ticking clock to build a sense of urgency. There will be a default message based on the options you selected, but you can also personalize the message.</p>
                                    <img src={getCloudFrontAssetsUrl('images/promotion_image.png')} alt='Promotion' className='my-2 mx-auto' />
                                    <p>We offer many different options for the ribbon look such as background colors, font colors and size, padding and even the initial animation of the banner to help grab the attention of your users. These options are in the Settings area under “Promotions Settings.” Play around with different options or feel free to contact us and we’ll adjust it for you. The default settings should work well for most sites.</p>
                                    <b>ENDING A PROMOTION EARLY</b>
                                    <p>The promotion will end by itself after the selected time-frame is over, but if you wish to stop a promotion before the designated time, simply go into the “SET PROMOTION” area again and while a promotion is running you’ll see an option to end it early.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.promotionModelOpen &&
                    <PromotionModal
                        handlePromotionModelClose={this.handlePromotionModelClose}
                        setPromotionButtonTextAfterSave={this.setPromotionButtonTextAfterSave}
                    />
                }
            </div>
        )
    }
}

Home.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    checkPromotion: PropTypes.func.isRequired,
    promotion: PropTypes.object.isRequired,
    influencerHelp: PropTypes.object,
    setHelpDataAsRead: PropTypes.func,
    influencerPopupHide: PropTypes.func,
    getAllPromotionOffers: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setUserHelpDataAsRead: PropTypes.func.isRequired,
    userInfluencerHelp: PropTypes.object,
    userInfluencerPopupHide: PropTypes.func,
    pushNotification: PropTypes.bool,
    sweetAlert: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    chat: state.chat,
    promotion: state.promotion,
    influencerHelp: state.influencerHelp,
    userInfluencerHelp: state.userInfluencerHelp,
    pushNotification: state.pushNotification,
    sweetAlert: state.sweetAlert
})

export default connect(
    mapStateToProps, { checkPromotion, setHelpDataAsRead, influencerPopupHide, getAllPromotionOffers, setUserHelpDataAsRead, userInfluencerPopupHide }
)(Home)
