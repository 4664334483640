/**
 * Get file extension
 * @param {string} fileName  file name
 * @returns {string} file extension
 */
const getFileExtension = (fileName) => {
    let items = fileName.split(/\.(?=[^.]+$)/)
    if (items.length === 2) {
        return items[1]
    }
    return ''
}

/**
 * Get file name and extension
 * @param {string} fileName  file name
 * @returns {Array<string>} file name and extension
 */
const getFileNameAndExtension = (fileName) => {
    return fileName.split(/\.(?=[^.]+$)/)
}

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '767px',
    tabletL: '991px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '1850px',
    desktopL: '2560px'
}

export { getFileExtension, getFileNameAndExtension, size }
