import React, { useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Button from '../Layouts/Button'
import ModalPopUp from '../Layouts/ModalPopUp'
import CryptoForm from './CryptoForm'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import CouponCodeInputBox from '../form/CouponCodeInputBox'

function AddFundForm(props) {
    const {
        wallet_settings,
        content_font_color,
        content_color,
        membership_price,
        recurring_membership_price,
        returning_membership_price,
        enable_promotion,
        returning_recurring_membership_price,
        enable_coupons
    } = props.auth.appSettings
    const { isSubscribedEver, wallet_amount, isRebillFailed } = props.auth.user

    const { isPaymentProcessing } = props.payment

    const { subscriptionPromotion } = props.promotion

    const addFundOptions = useMemo(() => {
        let options = []
        if (!_.isEmpty(wallet_settings.add_fund_default_options)) {
            options = wallet_settings.add_fund_default_options.split(',').filter((amount) => parseFloat(amount) > parseFloat(props.remainAmount))
        }
        return options
    }, [wallet_settings, props.remainAmount])

    const [fundAmount, setFundAmount] = useState(props.remainAmount === 0 ? addFundOptions[3] : props.remainAmount)
    const [fundOtherAmount, setFundOtherAmount] = useState(fundAmount)
    const [showOtherOption, setShowOtherOption] = useState(false)
    const [isStartedTransaction, setIsStartedTransaction] = useState(false)

    let addFundMinimumAmount = parseInt(wallet_settings.add_fund_minimum_amount, 10)
    let addFundMaximumAmount = parseInt(wallet_settings.add_fund_maximum_amount, 10)

    const onChange = () => {
        const regExp = /^[0-9]*(\.[0-9]{0,2})?$/
        if (_.isEmpty(fundOtherAmount)) {
            return props.setSweetAlert({ description: 'Please enter a valid amount' })
        }
        if (!regExp.test(fundOtherAmount)) {
            return props.setSweetAlert({ description: 'Please enter a valid amount' })
        }

        if (parseInt(fundOtherAmount, 10) > addFundMaximumAmount || parseInt(fundOtherAmount, 10) < addFundMinimumAmount) {
            return props.setSweetAlert({ description: `Choose a fund amount between ${addFundMinimumAmount} and ${addFundMaximumAmount}` })
        }
        setShowOtherOption(false)
        setFundAmount(fundOtherAmount)
    }

    const onStartTransaction = () => {
        setIsStartedTransaction(true)
    }

    const onCompleteTransaction = (updatedWalletBalance) => {
        setIsStartedTransaction(false)
        if (props.onCompleteTransaction !== undefined) {
            props.onCompleteTransaction(updatedWalletBalance)
        }
    }

    const buttonText = useMemo(() => {
        if (props.type === 'subscription') {
            return `ADD $${fundAmount} TO WALLET AND SUBSCRIBE`
        } else if (props.type === 'blog' || props.type === 'chat') {
            return `ADD $${fundAmount} TO WALLET AND UNLOCK`
        } else if (props.type === 'tips') {
            return `ADD $${fundAmount} TO WALLET AND TIP`
        } else {
            return `ADD $${fundAmount} TO WALLET`
        }
    }, [props.type, fundAmount])

    let initialMembershipPrice = Number(membership_price)
    let recurringMembershipPrice = Number(recurring_membership_price)
    if (isSubscribedEver === true) {
        initialMembershipPrice = Number(returning_membership_price)
        recurringMembershipPrice = Number(returning_recurring_membership_price)
    }

    const { couponCodeDetails, couponStatus } = props.couponCode

    if (_.isEmpty(couponCodeDetails) === false && couponStatus === 'valid') {
        if (couponCodeDetails.discount_type === 'percentage') {
            initialMembershipPrice = Number(initialMembershipPrice) - (Number(initialMembershipPrice) * couponCodeDetails.discount_value_for_initial / 100)
            if (couponCodeDetails.discount_value_for_recurring !== 0) {
                recurringMembershipPrice = Number(recurringMembershipPrice) - (Number(recurringMembershipPrice) * couponCodeDetails.discount_value_for_recurring / 100)
            }
        } else {
            initialMembershipPrice = Number(initialMembershipPrice) - couponCodeDetails.discount_value_for_initial
            if (couponCodeDetails.discount_value_for_recurring !== 0) {
                recurringMembershipPrice = Number(recurringMembershipPrice) - couponCodeDetails.discount_value_for_recurring
            }
        }
        initialMembershipPrice = parseFloat(initialMembershipPrice.toFixed(2))
        recurringMembershipPrice = parseFloat(recurringMembershipPrice.toFixed(2))
    }

    if (_.isEmpty(couponCodeDetails) && enable_promotion && subscriptionPromotion !== false) {
        if (subscriptionPromotion.applicable_to === 'ALL_USERS') {
            initialMembershipPrice = subscriptionPromotion.subscription_initial_amount
            recurringMembershipPrice = subscriptionPromotion.subscription_recurring_amount
        } else if (isSubscribedEver === true && subscriptionPromotion.applicable_to === 'OLD_USERS') {
            initialMembershipPrice = subscriptionPromotion.subscription_initial_amount
            recurringMembershipPrice = subscriptionPromotion.subscription_recurring_amount
        } else if (isSubscribedEver !== true && subscriptionPromotion.applicable_to === 'NEW_USERS') {
            initialMembershipPrice = subscriptionPromotion.subscription_initial_amount
            recurringMembershipPrice = subscriptionPromotion.subscription_recurring_amount
        }
    }

    if (_.isEmpty(couponCodeDetails) && props.resubscriptionOffer.isUserEligibleForOffer === true) {
        if (props.resubscriptionOffer.offer.give_free_month_subscription !== 0) {
            initialMembershipPrice = '0.00'
        } else {
            initialMembershipPrice = props.resubscriptionOffer.offer.recurring_price
        }
        recurringMembershipPrice = props.resubscriptionOffer.offer.recurring_price
    }

    const getPriceText = () => {
        if (props.type === 'subscription') {
            let text = ''
            if (parseFloat(initialMembershipPrice) === 0.00) {
                return `Our minimum cryptocurrency funding amount is $${addFundMinimumAmount}.`
            } else {
                if (parseFloat(recurringMembershipPrice) === parseFloat(initialMembershipPrice)) {
                    text = `$${initialMembershipPrice}/month`
                } else {
                    text = `$${initialMembershipPrice} for First Month then $${recurringMembershipPrice}/month`
                }
            }
            return `Your purchase request is for ${text}. Our minimum cryptocurrency funding
            amount is $${addFundMinimumAmount}.`
        } else if (props.type === 'blog' || props.type === 'chat' || props.type === 'tips') {
            if (props.remainAmount === 0) {
                return `Your purchase request is for $${props.transactionAmount}. Our minimum cryptocurrency funding
                amount is $${addFundMinimumAmount}.`
            } else {
                return `Your purchase request is for $${props.transactionAmount} and your current balance is $${wallet_amount}. You will need to fund your balance with at least $${Math.ceil(props.transactionAmount - wallet_amount)}.`
            }
        }
        else {
            return ''
        }
    }

    return <WebsiteFontColor>
        <div className='p-3 text-center'>
            {isStartedTransaction === false &&
                <>
                    <h3 className='mb-2'>CRYPTO FUNDING AMOUNT</h3>
                    <p className='text-start'>
                        {getPriceText()} Please choose an amount to fund your balance via this cryptocurrency
                        transaction. You can choose any amount up to ${addFundMaximumAmount} USD. All funds will
                        be kept in site balance and will be available for future
                        transactions on this site.
                    </p>
                    <div>
                        {props.remainAmount !== 0 && addFundOptions.includes(props.remainAmount.toString()) === false &&
                            <Button
                                type='button'
                                onClick={() => setFundAmount(props.remainAmount)}
                                classes={'px-4'}
                                style={{
                                    fontSize: '16px',
                                    backgroundColor: fundAmount === props.remainAmount ? content_color : content_font_color,
                                    color: fundAmount === props.remainAmount ? content_font_color : content_color
                                }}
                            >
                                ${props.remainAmount}
                            </Button>
                        }
                        {addFundOptions.length > 0 && addFundOptions.map((tip, index) => {
                            return <Button
                                key={index}
                                type='button'
                                onClick={() => {
                                    setFundAmount(tip)
                                    setFundOtherAmount(tip)
                                }}
                                classes={'px-4'}
                                style={{
                                    fontSize: '16px',
                                    backgroundColor: fundAmount === tip ? content_color : content_font_color,
                                    color: fundAmount === tip ? content_font_color : content_color
                                }}
                                loading={false}
                            >
                                ${tip}
                            </Button>
                        })}
                        <Button
                            type='button'
                            onClick={() => setShowOtherOption(true)}
                            classes={'px-4'}
                            style={{
                                fontSize: '16px',
                                backgroundColor: content_font_color,
                                color: content_color
                            }}
                            loading={false}
                        >
                            Other
                        </Button>
                    </div>
                    {enable_coupons && props.type === 'subscription' && isRebillFailed !== true &&
                        <CouponCodeInputBox
                            classes='mt-3 text-start'
                        />
                    }
                    <div className='mt-3'>
                        <Button
                            type='button'
                            onClick={onStartTransaction}
                            classes={'px-4'}
                            style={{ fontSize: '16px', whiteSpace: 'break-spaces' }}
                            loading={isPaymentProcessing}
                        >
                            {buttonText}
                        </Button>
                    </div>
                    {showOtherOption &&
                        <ModalPopUp
                            title='Other amount'
                            handleClose={() => setShowOtherOption(false)}
                            closeBtnFontSize='initial'
                            expandBackground={true}
                            parentModalId='add-fund-wallet'
                        >
                            <input
                                type='number'
                                name='amount'
                                className='form-control'
                                value={fundOtherAmount}
                                onChange={(e) => setFundOtherAmount(e.target.value)}
                                min={addFundMinimumAmount}
                                max={addFundMaximumAmount}
                                placeholder='Enter fund amount'
                            />
                            <Button classes='mt-5'
                                type='button'
                                onClick={onChange}
                                loading={false}
                            >
                                Confirm
                            </Button>
                        </ModalPopUp>
                    }
                </>
            }
            {isStartedTransaction &&
                <CryptoForm
                    requestFrom={props.requestFrom}
                    onHideAddFundsForm={props.onHideAddFund}
                    hideCryptoFormStatus={props.type}
                    onHideCryptoForm={props.onHideAddFundForm}
                    amount={fundAmount}
                    onChangeAmount={() => setIsStartedTransaction(false)}
                    onCompleteTransaction={onCompleteTransaction}
                />
            }
        </div>
    </WebsiteFontColor>
}

AddFundForm.propTypes = {
    auth: PropTypes.object.isRequired,
    remainAmount: PropTypes.number,
    onCompleteTransaction: PropTypes.func,
    type: PropTypes.string.isRequired,
    promotion: PropTypes.object.isRequired,
    resubscriptionOffer: PropTypes.object.isRequired,
    transactionAmount: PropTypes.number,
    onHideAddFund: PropTypes.func,
    onHideAddFundForm: PropTypes.func,
    requestFrom: PropTypes.string,
    setSweetAlert: PropTypes.func,
    payment: PropTypes.object.isRequired,
    couponCode: PropTypes.object.isRequired
}

AddFundForm.defaultProps = {
    remainAmount: 0
}

const mapStateToProps = state => ({
    auth: state.auth,
    promotion: state.promotion,
    resubscriptionOffer: state.resubscriptionOffer,
    payment: state.payment,
    couponCode: state.couponCode
})

export default connect(mapStateToProps, { setSweetAlert })(withRouter(AddFundForm))
