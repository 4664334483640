import {
    GET_BLOG_DATA,
    ADD_NEW_BLOG,
    IS_LOADING,
    UPDATE_BLOG_DATA,
    RESET_CURRENT_PAGE,
    SET_BLOG_IN_PROCESSING,
    UPDATE_FEED_LIKE_COUNT,
    SET_ROTATE_IMAGE,
    SELECTED_FEED_TAG,
    UPDATE_FEED_TAG_LIST,
    SET_FEED_TAG,
    REMOVE_BLOG_DATA,
    RESTORE_FIRST_PAGE_BLOGS
} from '../actions/types'
import _ from 'lodash'

const initialState = {
    blog: [],
    isLoading: true,
    totalPages: 0,
    currentPage: 0,
    isBlogMenu: true,
    tagList: [],
    selectedTag: '',
    isPaginationEnabled: false,
    // handles pagination Whenever user signs-up after exploring locked content
    firstPageBlogs: []
}

export default function (state = initialState, action) {
    let newBlogs = []
    let updatedBlogData = action.payload
    let newBlogsData = []
    let blog = [...state.blog]
    let isFirstPageBlogs = false
    switch (action.type) {
        case GET_BLOG_DATA:
            if (action.payload.currentPage === 1 || action.payload.isPaginationEnabled) {
                newBlogs = action.payload.blogs
            } else {
                newBlogs = state.blog.concat(action.payload.blogs)
            }

            isFirstPageBlogs = action.payload.currentPage === 1
            return {
                ...state,
                blog: newBlogs,
                isLoading: false,
                totalPages: action.payload.totalPages === 0 ? 1 : action.payload.totalPages,
                currentPage: parseInt(action.payload.currentPage),
                isPaginationEnabled: action.payload.isPaginationEnabled,
                firstPageBlogs: isFirstPageBlogs ? newBlogs : state.firstPageBlogs
            }
        case ADD_NEW_BLOG:
            if (state.selectedTag !== '') {
                if (!_.isEmpty(action.payload.feed_tags) && Object.values(action.payload.feed_tags[0]).indexOf(state.selectedTag) !== -1) {
                    // Push only if pagination current page is set to 1
                    if (!(state.isPaginationEnabled && state.currentPage > 1)) {
                        newBlogs.push(action.payload)
                    }
                }
            } else {
                // Push only if pagination current page is set to 1
                if (!(state.isPaginationEnabled && state.currentPage > 1)) {
                    newBlogs.push(action.payload)
                }
            }
            state.blog.forEach(element => {
                newBlogs.push(element)
            })
            return {
                ...state,
                blog: newBlogs,
                isLoading: false
            }
        case UPDATE_BLOG_DATA:
            for (let i = 0; i < state.blog.length; i++) {
                let element = state.blog[i]

                if (element._id === updatedBlogData._id) {
                    element = updatedBlogData
                    newBlogsData.push(element)
                } else {
                    newBlogsData.push(element)
                }
            }
            return {
                ...state,
                blog: newBlogsData,
                isLoading: false
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case RESET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case SET_BLOG_IN_PROCESSING:
            for (let i = 0; i < state.blog.length; i++) {
                let element = state.blog[i]

                if (element._id === updatedBlogData) {
                    element.processing = true
                    element.isError = false
                }
                newBlogsData.push(element)
            }
            return {
                ...state,
                blog: newBlogsData
            }
        case UPDATE_FEED_LIKE_COUNT:
            for (let i = 0; i < blog.length; i++) {

                if (blog[i]._id === action.payload.id) {
                    blog[i].user_feed_like = action.payload.is_like
                    blog[i].total_likes = action.payload.total_likes
                }
            }
            return {
                ...state,
                blog: blog
            }
        case SET_FEED_TAG:
            const feed_data = action.payload
            const userIndex = blog.findIndex((blogs) => blogs._id === action.payload._id)
            blog[userIndex].feed_tags = feed_data.feed_tags

            return {
                ...state,
                blog: blog
            }
        case SELECTED_FEED_TAG:
            return {
                ...state,
                selectedTag: action.payload
            }

        case UPDATE_FEED_TAG_LIST: {
            let feedTags = []
            if (action.payload.length > 0) {
                feedTags = action.payload.map(val => (
                    { value: val._id, label: val.name }
                ))
            }
            return {
                ...state,
                tagList: feedTags
            }
        }
        case SET_ROTATE_IMAGE: {
            const imageRotate = action.payload
            const userIndex = blog.findIndex((blogs) => blogs._id === imageRotate._id)
            blog[userIndex].media = imageRotate.media
            if (blog[userIndex].url !== undefined) {
                blog[userIndex].url = imageRotate.url
            }
            return {
                ...state,
                blog: blog
            }
        }
        case REMOVE_BLOG_DATA:
            const { id } = action.payload
            const blogs = state.blog.filter(function (blog) {
                return blog._id.toString() !== id.toString()
            })

            return {
                ...state,
                blog: blogs
            }
        case RESTORE_FIRST_PAGE_BLOGS:
            return {
                ...state,
                blog: state.firstPageBlogs,
                currentPage: 0
            }
        default:
            return state
    }
}
